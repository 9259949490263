import React from 'react';
import BoxComponent from './BoxComponent';
import { DsyDivider } from '../../dsy-wrappers';
import RisparmioBox from './RisparmioBoxComponent';
import FotoVirtuale from "../../icons/icon/new/FotoVoltaicoVirtuale.png"
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import SelectAtViewComponent from './SelectAtView';
import night from "../../icons/icon/new/night_b.svg"

function BoxFotoVirtualeComponent() {
    const dispatch = useDispatch();
    const data = useSelector(get);
    return (
        <div className="foto-box-virtuale">
            <BoxComponent 
                disabled={data["ConsumoOrario"] === false}
                title="Solare Virtuale"
                serie="fotovoltaico virtuale"
                tags={["Energia gratuita dalle 10:00 alle 17:00"]}
                image={"FotoVirtuale"}
                description={"Ideale per chi non può installare un pannello fotovoltaico a casa o vuole ampliare l'energia solare senza interventi. Con Easy Solare, usufruisci della stessa energia solare di un impianto tradizionale, con la componente energetica azzerata dalle 10:00 alle 17:00. L'energia prodotta dalla tua quota verrà assegnata virtualmente al tuo contatore, riducendo la bolletta come se i pannelli fossero a casa tua."}
                />

            <div className="my-4" aria-hidden="true"><DsyDivider /></div>

            <div className="bold-title">Consumi almeno il 40% della tua energia elettrica</div>
            <div className="gen-subtitle">Vogliamo verificare se questa soluzione potrebbe essere conveniente per te</div>

            <SelectAtViewComponent 
                cell="ConsumoOrario"
                choices={[{
                    "text": "Si, consumo almeno il 40% in questi orari",
                    "value": true,
                    "image": 5
                },{
                    "text": "No, consumo meno del 40% in questi orari",
                    "value": false,
                    "image": 6
                }]}
            />

            { data["ConsumoOrario"] === false ? <div className="d-flex blue-box f14">
                <img src={night} role="presentation" alt="" className="me-3" />
                <div>Sembra che la tua casa <b>consumi energia</b> principalmente al di <b>fuori delle ore solari</b>, quindi il <b>Solare Virtuale potrebbe non essere la scelta più conveniente per te</b>. Ma non preoccuparti, abbiamo <b>altre soluzioni per rendere la tua casa più efficiente</b> dal punto di vista energetico. Vai avanti e scopri come puoi migliorare la tua casa con le nostre altre proposte!
                </div>
            </div> :
            <>
                <div className="mt-4 mb-2">
                    <DsyDivider />
                </div>
                <div className="small f14 " style={{ color: "#667790"}}>
                * Il prezzo si riferisce alla sola componente energia (IVA e imposte escluse) ed è bloccato per i primi 36 mesi. Tale prezzo è comprensivo delle perdite di rete che sono applicate come definite, pubblicate e aggiornate da ARERA. L’extra-consumo nelle ore solari (10-17) avrà il prezzo a consumo della componente energia delle ore non solari (17-10).
                </div>
            </>}
            {/* <RisparmioBox 
                title="Solare Virtuale"
                description="da 1.0kW"
                tag="Offerta luce"
                image={FotoVirtuale}
                attualeTitle={"Costo iniziale"}
                attualeDescription={"IVA inclusa"}
                attualeValue={"99,00€"}
                futuraTitle={"Abbonamento mensile"}
                futuraDescription={"IVA inclusa"}
                futuraValue={"13 € + CCV 10 €/mese"}
             /> */}
        </div>
    );
}

export default BoxFotoVirtualeComponent;