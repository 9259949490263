import React, {useState} from 'react';
import {
    set, get
} from '../../reducers/data';
import { useSelector } from 'react-redux';

function LabelComponent({ name, extraClass,cell, component, description }) {
    const data = useSelector(get);

    return (<>
        {component ? 
        <h2 className={"label-component " + (extraClass ? extraClass : "") } >
          <div className="title" dangerouslySetInnerHTML={{ __html:
          cell ? name.replaceAll("{cell}", data[cell]) : name }}></div>
          {description && <div className="subtitle">{description}</div>}
        </h2>:<div className={"label-component  " + (extraClass ? extraClass : "") } ><div className="title" dangerouslySetInnerHTML={{ __html:
          cell ? name.replaceAll("{cell}", data[cell]) : name }}></div>
          {description && <div className="subtitle">{description}</div>}
        </div>}
       </>)
}

export default LabelComponent;