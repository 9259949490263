import React, { useState } from 'react'
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import pig from '../../../icons/icon/pig.svg'
import money from '../../../icons/icon/pay-per-use.svg'
import world from '../../../icons/icon/world.svg'
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../../reducers/data';

function BlueboxComponent ({title, title1, num1, title2, num2, children}) {
  return (
    <div className='bluebox flex-column'>
      <div className="left">
        <div className='btitle f18' style={{ color: "#131416"}}>{title}</div>
        <div className='bsubtitle'>{children}</div>
      </div>
      <div className='d-flex justify-content-between right'>
        <div className="subl">
          <div className="tit">{title1}</div>
          <div className='number'>{num1}</div>
        </div>
        <div className="subr">
          <div className="tit">{title2}</div>
          <div className='number'>{num2}</div>
        </div>
      </div>
    </div>
  )
}

function BoxInfo ({ title, icon, children, subtitle, disclaimer }) {
  return (
    <>
      <Col className='box col-12 col-lg-4 mb-4 mb-lg-0'>
        <div className='h-100 d-flex flex-column'>
          
          <div className='box-content'>
            <div className='d-flex box-top'>
              <div className='icon-box'>
                <img src={icon} alt="" role="presentation" />
              </div>
              <div>
                <div className='box-info red f16' >{title}</div>
                <div className='sub f12' dangerouslySetInnerHTML={{ __html: subtitle}}></div>
              </div>
            </div>
            <div className='box-middle'>
            {children}
            </div>
          </div>
        </div>
      </Col>
      {disclaimer && <div className='disclaimer'>{disclaimer}</div>}
    </>
  )
}

function BeneficiFinaliComponent () {
  const data = useSelector(get);
  const Induzione = data?.products?.length === 1 && data?.products?.find(p => p.name === "Induzione") !== undefined;
  let risparmioAnnuo =  "N/A"

  if (data["RisparmioAnnuo"])
    risparmioAnnuo = parseFloat(data["RisparmioAnnuo"]).toLocaleString().replaceAll(",", ".")
  return (
    <Container fluid className='benefici'>
        <BlueboxComponent 
        title="Stima dell'impegno economico"
        title1="Investimento"
        num1={data["Investimento"]}
        title2="Recupero totale in"
        num2={data["RecuperoTotale"] !== "Assetto scelto non conveniente" ? data["RecuperoTotale"] + " anni": "Assetto scelto non conveniente"} >
          che dovresti sostenere adottando le soluzioni proposte qui sopra. Offriamo anche la possibilità di finanziamento.
        </BlueboxComponent>

          <div className="f20 bold mt-5">Ti stai chiedendo "Quali sono i benefici"?</div>
            <div className="small-text mb-4">Eccoli qui! Se decidi di confermare questa soluzione otterrai i seguenti benefici:</div>

      <Row className='same-height'>
        <BoxInfo
          title='Benefici economici'
          icon={pig}
          subtitle='Ecco la stima del tuo <b>risparmio totale annuo</b> sui consumi in bolletta'
        >
          <div className='d-flex box-bottom justify-content-between'>
            <div className='left'>
              <div className='mb-8'>Spesa annua</div>
              <div className='d-flex justify-content-between'>
                <div>
                  <div>Attuale</div>
                  <div className='money'>{data["SpesaAnnuaAttuale"]?.replaceAll(",", ".")}€</div>
                </div>
                <div>
                  <div>Futura</div>
                  <div className='money'>{data["SpesaAnnuaFutura"]?.replaceAll(",", ".")}€</div>
                </div>
              </div>
            </div>
            <div className='right align-content-between'>
              <div className='mb-8'>Risparmio annuo</div>
              <div>
                <div className='discount'>{(Math.abs(data["RisparmioPerc"] * 100))?.toFixed(0)}%</div>
                <div className='money'>{Math.abs(risparmioAnnuo) + "€"}</div>
              </div>
            </div>
          </div>
        </BoxInfo>

        {data["CO2"] != 0 && data["CO2"] != undefined && data["CO2"] != null && data["CO2"] != "0 kg" &&
        <BoxInfo title={'Benefici ambientali'} icon={world} subtitle='Ecco quanta produzione di <b>CO2</b> potresti evitare, riducendo il tuo impatto sul pianeta'>
          <div className='d-flex box-bottom justify-content-end'>
            <div className='co2'>
              <div className='money'>{data["CO2"]?.replaceAll(",", ".")}/anno</div>
              <div className="alberi">Equivalenti alla CO2 assorbita da {data["Alberi"]} </div>
            </div>
          </div>
        </BoxInfo> }

        {/* <BoxInfo
          title='Investimento'
          icon={money}
          subtitle={'Impegno economico'+ (Induzione ? "": "*") }
        >
           <div className='d-flex box-bottom h-100 align-items-end justify-content-end'>
            <div className='left'>
              <div className='mb-8'>Investimento</div>
                  <div className='money'>{data["Investimento"]}</div>
            </div>
            <div className='right align-content-between'>
              <div className='mb-8'>Recupero totale in</div>
              <div className='money'>{data["RecuperoTotale"] !== "Assetto scelto non conveniente" ? data["RecuperoTotale"] + " anni": "Assetto scelto non conveniente"}</div>
            </div>
          </div>
          </BoxInfo> */}
      </Row>
      {/* {data["extraco2"] && data["extraco2"].length > 1 && <div style={{ paddingTop: "10px" }} className="disclaimer text-start d-md-none" dangerouslySetInnerHTML={{ __html: data["extraco2"]}}></div>}
      {Induzione === false &&
      <div className="disclaimer text-start d-md-none">
      *con possibilità di finanziamento
      </div>} */}

      {/* <Row className='d-none d-md-flex flex-md-row-reverse flex-lg-row'>
        <Col></Col>
        <Col className="diclaimer" dangerouslySetInnerHTML={{ __html: data["extraco2"]}}></Col>
        <Col className="disclaimer">{Induzione === false ? "*con possibilità di finanziamento" : ""}</Col>
      </Row> */}

      <Row>
        
        <div className='spacer my-32'></div>
      </Row>
    </Container>
  )
}

export default BeneficiFinaliComponent
