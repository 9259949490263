
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { Children } from 'react';
import { Modal, Container, ButtonGroup, Button, Row, Col } from 'react-bootstrap';
import RenderOptionComponent from './RenderOptionComponent';
import closeIcon from "../../icons/icon/CloseModal.svg"

function SelectPopupComponent({ name, cell, choices, close, show, chiudi }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const currentValue = data[cell]
    const showName = name.includes("100%") ? "Offerta 100% Rinnovabili" : name

    const closeCopy = ["C25", "C26", "C27"].includes(cell) ? "Chiudi" : "Annulla"

    return (
        <Modal show={show} 
            centered={true}
            aria-modal="true"
            className="select-popup"
            animation={false}
            aria-label={"Scegli " + name}
            role="dialog"
            fullscreen={true} 
            backdrop={false}>

            <Container fluid className="title d-flex justify-content-between align-items-center" >
                <div className="field-name">{showName}</div>
                <button className="accent no-bg no-border" tabIndex={0} onClick={() => { dispatch(set({cell: cell, value: undefined})); close()}}>{ closeCopy }<img height={14} src={closeIcon} alt="Chiudi" /></button>
            </Container>
            {choices.find(x => x === "Non so" || x === "Non lo so") && 
            <Container fluid className="non-lo-so">
                <Row className="so-non-so mt-2">
                    <Col>
                    <ButtonGroup className="w-100  mt-2">
                        <Button className={currentValue !== "Non so" ? "sel" : ""}
                          onClick={() => dispatch(set({
                            cell: cell,
                            value: undefined
                        }))}>Conosco {showName}</Button>
                        <Button className={currentValue === "Non so" ? "sel" : ""}
                            onClick={() => dispatch(set({
                                cell: cell,
                                value: "Non so"
                            }))}
                        >Non so</Button>
                    </ButtonGroup>
                    </Col>
                </Row>
            </Container>}
            
            <Container role="list" fluid className={"options " + (currentValue === "Non so" ? "disabled" : "") }>
                {choices.filter(x => x !== "Non so" || x.length === 0).map(choice => <RenderOptionComponent
                    name={showName}
                    cell={cell}
                    action={choice}
                    key={choice}
                />)}
            </Container>
            <div className="confirm d-flex justify-content-center align-items-center">
                <button 
                    aria-label='Conferma'
                    disabled={currentValue === undefined || currentValue === ""}
                    onClick={close}
                >Conferma</button>
            </div>

        </Modal>
    );
}

export default SelectPopupComponent;
