
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { useState} from 'react';
import InfoButton from './InfoButtonComponent';
import { Button } from 'react-bootstrap';
// import SliderWithThumbComponent from './SliderWithThumbComponent';

import casa from "../../icons/buttons/casa.svg"
import mesi from "../../icons/buttons/mesi.svg"
import inquilini from "../../icons/buttons/inquilini.svg"
// import riscaldamento from "../../icons/buttons/riscaldamento.svg"

import meno from "../../icons/buttons/meno.svg"
import menoBianco from "../../icons/buttons/menobianco.svg"
import piu from "../../icons/buttons/piu.svg"
import riscaldamento from "../../icons/icon/riscaldamento_r.svg"

const imgs = {
    casa,
    mesi,
    inquilini,
    riscaldamento
}

function StepperComponent({ name, cell, min, max, step, info, defaultValue, icon, description, text, extraClass, postChange }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const errorMessage =  "Inserire un valore compreso tra " + min + " e " + max
    const [error, setError] = useState(data[cell] >= min && data[cell] <= max ? undefined : errorMessage)
    let currentValue = data[cell]
    
    if (!currentValue)
        currentValue = defaultValue

    const checkAndSet = (cell, value) =>{
        if (min && value < min) {
            setError(errorMessage)
        }
        else if (max && value > max) {
            setError(errorMessage)
          
        } else setError(undefined)

        dispatch(set({
            cell: cell,
            value: value
        }))
        if (postChange)
            postChange(value)
    }
   
    return (<>
        <div className={"slider-component mb-16 d-flex flex-column stepper-component"}>
            <div className={"d-flex align-items-center mw320 justify-content-between "  }><h2 className={ "pre mb-0 title" }>{name} </h2>{info && <InfoButton {...info} />}</div>
            {description &&<div className='subtitle'>{description}</div> }
            <div className={extraClass}>
            <div className={"stepper-container mt-3"}>
                <div className="stepper-label">
                    <img className="stepper-img" src={imgs[icon]} alt={""} />
                    <div aria-hidden={true}>{text}</div>
                </div>
                <div className="d-flex justify-content-center stepper-component">
                    <button 
                        className={"stepper-button " + (currentValue === min ? " disabled" : "")} 
                        aria-label={"riduci " + name}
                        onClick={() => checkAndSet(cell, parseInt(currentValue) - step)} disabled={currentValue === min} >
                            <img src={currentValue === min ? meno : menoBianco} alt="riduci valore" role="presentation" />
                    </button>
                    <div>
                    <input type="number" 
                        inputMode="numeric"
                        aria-live={"assertive"}
                        id={"numInput" + cell} 
                        aria-relevant="additions text" 
                        aria-atomic="true"
                        className={"number-selector " + (error ? "error" : "")} 
                        value={currentValue} 
                        aria-label={name + " valore minimo " + min + " massimo " + max}
                        onChange={(e) => {
                            if (step && step == 1) {
                                dispatch(set({
                                    cell: cell,
                                    value: parseInt(e.currentTarget.value)
                                }))
                            }
                            else
                            {
                                dispatch(set({
                                    cell: cell,
                                    value: parseFloat(e.currentTarget.value)
                                }))
                            }
                        } 
                        }
                        onBlur={(e) =>{
                            checkAndSet(cell, parseInt(currentValue))}
                        }  min={min} max={max} step={step ?? 1} 
                    />
                    {error && <div className="small d-flex" aria-errormessage={error}>{error}</div>}
                    </div>
                    <button className={"stepper-button " + (currentValue >= max ? " disabled" : "")} 
                            aria-label={"aumenta "  + name }
                            onClick={() => checkAndSet(cell, parseInt(currentValue) + step)} disabled={currentValue >= max} >
                            <img src={piu} alt="aumenta valore" role="presentation"/>
                    </button>
                    <div id="liveRegion" aria-live="polite" aria-atomic="true" className="visually-hidden">Valore attuale {currentValue} {error ? " non consentito in quanto non tra minimo e massimo" : ""}</div>
                </div>
            </div>
            </div>
        </div>
    </>
    );
}

export default StepperComponent;