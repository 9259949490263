
import React from 'react';
import { DsyDivider } from '../../dsy-wrappers';


function DisclaimerComponent({ title, subtitle }) {

    return (<div className="mt-3 disclaimer-component">
            <div aria-hidden="true"><DsyDivider ></DsyDivider> </div>
            <div className="disclaimer-title mt-3 mb-1">{title}</div>
            <div className="disclaimer-subtitle">{subtitle}</div>
        </div> 
    );
}

export default DisclaimerComponent;