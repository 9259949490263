
import { GriButton } from "../dsy-inhouse/GriButtonComponent"

function InfoModal ({ title, subtitle, close, keyDown }) {
  return (
    <div className='info-box' role='dialog' onKeyDown={keyDown} tabIndex={0}>
      <div className='content'>
        <div className='title'>{title}</div>
        <div
          className='text'
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></div>
        <GriButton fullWidth={true} onClick={close} id="confirm" label={"Chiudi"}>
        {/* <button onClick={close} className='big-accent'> */}
          OK
        </GriButton>
      </div>
     
    </div>
  )
}

export default InfoModal
