import React, { useRef, useState, useEffect} from 'react'

import { useSelector } from 'react-redux';
import { set, get } from '../../../reducers/data';
import Lottie from "react-lottie-player"
import animation from "../../../animations/Giorno-notte.json"
import fuochi from "../../../animations/Fuochi-d'artificio.json"
import casaMagica from "../../../animations/casa-magica.json"

import handlePage0 from './handlePage0';
import handlePage1 from './handlePage1';
import handlePage2 from './handlePage2';
import handlePage3 from './handlePage3';
import handlePage4 from './handlePage4';
import handlePage5 from './handlePage5';
import handlePage9 from './handlePage9';

const usePageLoad = () => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (document.readyState === "complete") setLoaded(true)
    else {
      window.addEventListener('load', () => setLoaded(true))
    }
    return () => {
      window.removeEventListener('load', () => setLoaded(true))
    }
  }, [])
  return loaded
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};


function Wrapper({defProps, extraClass, children}){
  const defAdj =  { ...defProps, wrapper: null}
  return <div ref={defProps.wrapper} id="image-wrapper" 
          aria-hidden={true}
          className={extraClass ?? ""} 
          style={{  position: "relative", display: "flex", flex: "1 1 0px"}}>
        <div {...defAdj} > 
          {children}
        </div>
  </div>
}

export default function BannerImageComponent({extraClass, pageTitle, imageId}) {
  const data = useSelector(get);
  const bannerImage = useRef(null)
  const wrapper = useRef(null)
  const [animationRunning, setAnimationRunnning] = useState(true) 
  const { loaded } = usePageLoad()
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(undefined)
  const [block,setBlock] = useState(false)
  let toRender = []
  const { width1, height1 } = useWindowSize();

  useEffect(() => { 
    // if (animationRunning === false)
    //   setAnimationRunnning(true)
    // setTimeout(() => { setAnimationRunnning(false) }, 5000)
  }, [data["screen"]])

  const clientWidth = bannerImage.current?.clientWidth
  if (!block && clientWidth && bannerImage.current?.checkVisibility()){
    let finalHeight = clientWidth * 1.45454545
    const wrapperHeight = wrapper.current?.clientHeight

    if (bannerImage.current?.classList.contains("d-none") && wrapperHeight && wrapperHeight < finalHeight) {
      if (Math.abs(wrapperHeight - height) > 2){
        finalHeight = wrapperHeight
        setWidth(wrapperHeight / 1.45454545)
        setHeight(finalHeight)
        return
      } 
    }
    else {
      if (width !== undefined && Math.abs(finalHeight - wrapperHeight) > 2){
        setWidth(undefined)
      }
        
      if(finalHeight !== height && Math.abs(finalHeight - wrapperHeight) > 1)
        setHeight(finalHeight)
    }
  }

  const defProps = {
    wrapper: wrapper,
    className: extraClass,
    ref: bannerImage,
    style: { zIndex: 10, height: height + "px"},
    id: "banner-image",
    'aria-hidden': true,
    screenId: data["screen"]
  }
  if (width) defProps.style.width = width + "px"
  
  if (imageId === "casa-magica") return <Wrapper defProps={defProps} extraClass="casa-magica">
    <Lottie duration={5000} controls={false} play={animationRunning} loop={false} animationData={casaMagica} />
  </Wrapper>

  if (pageTitle === "La potenza del tuo contatore"){
    const potenza =   handlePage9(data)
    return <Wrapper defProps={defProps}>
         {potenza.map((x,i) =>  { return {...x, key: i }})}
         </Wrapper>
  }
    
  if (pageTitle === "Scopri in pochi minuti come migliorare l'efficienza energetica della tua casa e risparmiare sui consumi!") {
    return  <Wrapper defProps={defProps}> 
      <Lottie controls={false} play={animationRunning} loop={false} animationData={animation} /> 
     </Wrapper>
  }
  switch(data["pageIndex"] || 0){
    case 0: 
      toRender = handlePage0(data)
    break;
    case 1:
      toRender = handlePage1(data)
    break;
    case 2:
      toRender = handlePage2(data)
    break;
    case 3:
      toRender = handlePage3(data)
    break;
    case 4:
      toRender = handlePage4(data)
    break;
    case 5:
      // toRender = handlePage9(data)
      // break;
    case 6:
    case 7:
    case 8:
      toRender = handlePage5(data, pageTitle)
    break;
    // case 9:
    //   toRender = handlePage9(data)
    //   break;
    case 100:
      toRender = [ <Lottie controls={false} play={animationRunning} loop={false} animationData={fuochi} /> , ...handlePage0(data)]
    break;
    default:
      toRender = []
  }



  return (
    <Wrapper defProps={defProps}>
          {toRender.map((x,i) =>  { return {...x, key: i }})}
    </Wrapper>
  )
}
