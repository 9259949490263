import React, {useState} from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import ContactsModalComponent from '../ContactsModalComponent';
// import { DsyButton } from '../../../dsy-wrappers';
import { GriButton } from '../../dsy-inhouse/GriButtonComponent';

function BookAppointment ({changeModal}) {
    const [showModal, setShowModal] = useState(false);
    return <Container fluid className="book-appointment">
        <Container>
        <Row> 
            <Col>
                {/* <h2>Prenota un appuntamento con un nostro operatore per finalizzare la tua soluzione</h2> */}
                {/* <p>Scegli la fascia oraria in cui intendi essere ricontattato</p> */}
            </Col>
            <Col className="d-flex justify-content-center align-items-md-center justify-content-md-center" style={{ position:"fixed", paddingTop: "16px", paddingBottom: "16px", bottom: "16px", left: 0, right: 0 }}  >
                <GriButton full-width={true} shape="square"  className="outline-btn" onClick={() => changeModal(true)}>Ricevi il report e prenota una consulenza</GriButton>
            </Col>
        </Row>
        </Container>
    </Container>
}

export default BookAppointment;