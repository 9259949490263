import React, {useState} from "react"
import { Container, Row, Col, Modal, Carousel, Accordion } from "react-bootstrap"
import closeIcon from "../../icons/icon/close.svg"

// function ControlledCarousel ({ img, copy }) {
//     const [index, setIndex] = useState(0)
  
//     const handleSelect = (selectedIndex, e) => {
//       setIndex(selectedIndex)
//     }
  
//     return (
//       <Carousel
//         id='bolletta-carousel'
//         activeIndex={index}
//         controls={false}
//         indicators={false}
//         onSelect={handleSelect}
//       >
        
//             <Carousel.Item key={index}>
//             <img className='d-block w-100' src={img} alt="" />
//             <div class="visually-hidden">{copy}</div>
//             </Carousel.Item>

//       </Carousel>
//     )
//   }

  function FacSimileComponent ({ show, close, img, copy, n, facsimile, top, bottom }) {
    return (
      <Modal
        aria-modal="true"
        role="dialog"
        show={show}
        centered={true}
        className='select-popup modal-pictures'
        animation={false}
        fullscreen={true}
        backdrop={false}
        aria-label="Visualizza FAC-SIMILE bolletta"
      >
        <Container
          fluid
          className='title d-flex justify-content-between align-items-center'
        >
          <div className='field-name'>Dove si trova questa informazione?</div>
       
        </Container>
  
        <Container fluid className="p-3">
        <Accordion className="" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Hai una bolletta ENEL?</Accordion.Header>
            <Accordion.Body>
             {top}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Hai una bolletta di un altro fornitore?</Accordion.Header>
            <Accordion.Body>
             {bottom}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
          {/* <Row>
            <ControlledCarousel img={img} copy={copy} />
          </Row>
          <Row >
            {facsimile ? 
               <Col className="p-4" dangerouslySetInnerHTML={{__html: facsimile.html}} >
               </Col>
               :
               <Col className="p-4" >
               <b> Pagina {n} della bolletta</b> <br />
               
               Se non riesci a trovare le informazioni, consulta la <b><a aria-label="Leggi la guida ufficiale in pdf sul sito Enel Energia, il collegamento apre in una nuova scheda" href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" target="_blank"> guida alla lettura della bolletta</a></b>        
           </Col>
               }
        </Row> */}
        </Container>
        <div style={{ position:"absolute", right:"16px", top: "16px", zIndex: 2147483647}} 
            role="button" 
            aria-label="Chiudi" 
            className='accent' 
            onKeyDown={(e) => { if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) close() }}
            onClick={close} 
            tabIndex={0}>
            <img height={14} src={closeIcon} alt='' role="presentation" />
          </div>
      </Modal>
    )
  }

  export default FacSimileComponent