
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { useState } from 'react';
import SelectPopupComponent from './SelectPopupComponent';
import arrow from "../../icons/icon/dropdownarrow.svg"
import  { components  } from 'react-select'
import AsyncSelect from "react-select/async"

import check from "../../icons/buttons/check.svg"

function SelectComponent({ name, cell, choices, extraClass, disabled }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const [open, setOpen] = useState(false)
    const currentValue = data[cell]

    const Control = ({ children, ...props }) => {

        const value = props.getValue()
        if (value.length === 0) 
            return (<components.Control {...props} className='empty-select-control'>
                {children}
            </components.Control>)
    
        return (<components.Control {...props} className='main-select-control'>
            {children} 
            </components.Control>)
    }
    
    const DropdownIndicatorArrow = (
        props
      ) => {
        return (
        //   <components.DropdownIndicator {...props}>
        <div className="dropdown-new">
            <img src={arrow} alt="" />
        </div>
        //   </components.DropdownIndicator>
        );
    };

    
    const CustomOption = (props) => {
        const { data, innerRef, innerProps } = props;
        const { tabIndex: _, ...rest } = innerProps;
        const currentValue = props.getValue()
        const isSelected = currentValue.length > 0 && currentValue[0].value === data.value

        let className = "non-selected-opt"
        if (isSelected) className = "selected-opt"
        return(
        <components.Option {...props} innerProps={{
            ...rest,
            role: "option",
            "aria-selected": isSelected
          }}>
                <div
        className={className }
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "space-between",
          padding: "4px",
            // height: "48px",
          width: "100%",
          position: "relative",
          zIndex: 2147483647
        }}
      >
                <div className="w-full d-flex justify-content-center align-items-center" >
                    {data.label}
                </div>
                {isSelected && <img src={check} className="check-freccia" alt="" />}
            </div>
        </components.Option>)
        //  <components.Option {...props}    innerProps={{
        //     ...rest,
        //     role: "option",
        //     "aria-selected": isSelected
        //   }} />
    };


    return (<>
        <div
            onClick={() => { console.log(disabled); if (disabled !== true) setOpen(true)}}
            tabIndex={0}
            onKeyDown={(e) => { if ((e.key === "Enter" || e.code == "Space" || e.keyCode == 32) && disabled !== true) setOpen(true) }}
            className={"select-component d-flex d-lg-none flex-column input-border justify-content-center " + (data ? "filled " : "")+ (extraClass ? extraClass : "")} >
            <div>
                {!currentValue ? <div className="fieldname" >{name}</div> :
                    <div className="select-with-value">
                        <div className="prop-name">{name}</div>
                        <div className="prop-value">{currentValue?.replaceAll(" e non ristrutturata di recente","")
                                                                    ?.replaceAll(" o recentemente ristrutturata senza coibentazione")
                                                                    ?.replaceAll(" o ristrutturata con coibentazione", "")
                                                                    ?.replaceAll("undefined", "") }</div>
                    </div>}
            </div>

            <img src={arrow} className="open-drawer" width={16} alt="Open Drawer" />
        </div>

        <div style={{ maxWidth: "430px"}} className={"d-none d-lg-block select-box " + (extraClass ? extraClass : "") }>
            <AsyncSelect
            defaultOptions={choices.map(x => { return { value: x, label: x}})}
            role="dialog"
            aria-live="polite"
            onKeyDown={e => e.stopPropagation()}
                //   options={choices.map(x => { return { value: x, label: x}})}
            className={"select-component-input d-none d-lg-block input-border "  + (extraClass ? extraClass : "")}
            // className="input-border select-component-input"
            styles={{ paddingTop: currentValue ? 10 : 0, 
                option: (base, state) => ({
                ...base,
                height: "48px",
                display:"flex",
                alignItems: "center",
                color: state.isSelected ? '#5738ff' : 'black',
                background: state.isFocused ? '#EFF2F7' : 'transparent', // TODO scegli el color al posto de grey
              }) }}
            value={currentValue}
            aria-label={name}
            isSearchable={false}
            isDisabled={disabled === true}
            ariaLiveMessages={{
                guidance: () => 'Premi le frecce su e giù per selezionare le opzioni',
                onChange: () =>  name + " selezionato: " + data[cell],
            }}
            components={{ 
                DropdownIndicator: DropdownIndicatorArrow, 
                Option: CustomOption, 
                Control: Control }} 
            onChange={(e) => dispatch(set({ cell: cell, value: e.value })) }
            style={{ maxWidth: "100%", paddingTop: currentValue ? 10 : 0 }}
      
             />

            { currentValue &&<div className="select-box-text">{name?.replaceAll(" e non ristrutturata di recente","")
                        ?.replaceAll(" o recentemente ristrutturata senza coibentazione")
                        ?.replaceAll(" o ristrutturata con coibentazione", "")
                        ?.replaceAll("undefined", "")  }</div>}
        </div> 

        <SelectPopupComponent name={name} show={open} close={() => setOpen(false)} choices={choices} cell={cell} />
    </>
    );
}

export default SelectComponent;
