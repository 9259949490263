import React, { useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { set, get } from '../../reducers/data'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import comuni from '../../utils/comuni.json'
import check from "../../icons/buttons/check.svg"

export default function CAPComponeent() {
    
  const [isCAPMenuOpen, setIsCAPMenuOpen] = useState(false)
  
  const data = useSelector(get)
  const dispatch = useDispatch()

  const zipCodes = comuni.filter(x => x.comune === data["C14"]).map(x => { return { value: x.cap, label: x.cap } })

  const onFocus= ({ focused, isDisabled }) => {
    const msg = `Stai selezionando ${focused.label}${
      isDisabled ? ', disabilitata' : ''
    }`;
    // setAriaFocusMessage(msg);
    return msg;
  };

  const  Option = props => {
    const { isSelected, innerProps, data } = props;
    const { tabIndex: _, ...rest } = innerProps;

    let className = "non-selected-opt"
    if (isSelected) className = "selected-opt"

    return (
      <components.Option {...props} innerProps={{
          ...rest,
          role: "option",
          "aria-selected": isSelected
        }}>
              <div
      className={className }
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "space-between",
        padding: "4px",
        width: "100%",
        position: "relative",
        zIndex: 2147483647
      }}
    >
              <div className="w-full d-flex justify-content-center align-items-center" >
                  {data.label}
              </div>
              {isSelected && <img src={check} className="check-freccia" alt="" />}
          </div>
      </components.Option>)
  }

  return (<>
  <div className='select-box' style={{ maxWidth: "310px", marginBottom: "1.25rem", paddingTop: "0"}}>
    <AsyncSelect
    className='cap'
    placeholder={<span aria-hidden="true">CAP</span>}
    isSearchable={true}
    onKeyDown={e => {
      if (e.key === "Enter") setIsCAPMenuOpen(true);
      e.stopPropagation();
    }}
    aria-live="polite"
    role="dialog"
    styles={{ paddingTop: data["CAP"] ? 10 : 0, option: (base, state) => ({
      ...base,
      color: state.isSelected ? '#5738ff' : 'black',
      background: state.isFocused ? '#EFF2F7' : 'transparent', // TODO scegli el color al posto de grey
    }) }}
    // isClearable={true}
    ariaLiveMessages={{
      onFocus,
      guidance: () => 'Premi le frecce su e giù per selezionare le opzioni',
      onFilter: () => zipCodes.length + ' opzioni disponibili',
      onChange: () => 'CAP selezionato ' + data['CAP']
    }}
    menuIsOpen={isCAPMenuOpen}
    onMenuClose={() => setIsCAPMenuOpen(false)} 
    onMenuOpen={() => setIsCAPMenuOpen(true)}
    aria-label='Seleziona o scrivi il CAP '
    aria-labelledby="CAP"
    defaultValue={data['CAP']}
    value={
      data['CAP'] ? { value: data['CAP'], label: data['CAP'] } : null
    }
    noOptionsMessage={() => "Nessuna corrispondenza"}
    components={{
      DropdownIndicator: null,
      Option
    }}
    onChange={e => 
        dispatch(set({ cell: "CAP", value: e.value }))
    }
    loadOptions={zipCodes}
    defaultOptions={zipCodes}
    // loadOptions={(val, cb) => cb(zipCodes)}
    // options={zipCodes}
  />
  {data['CAP'] && <div aria-hidden={true} className='select-box-text' style={{ top: "6px", left: "10px"}}>CAP</div>}
  {data["C14"] && <div aria-hidden={true} className='select-box-text bold' style={{ color: "#131416", top: "24px", left: "unset", right: "12px"}}>{data["C14"]}</div>}
  </div>
  </>
  )
}
