import React, { useState } from 'react'
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { set, get } from '../../../reducers/data'
import { MigrationContentSwitcher } from '../../../dsy-wrappers/dsy-migration-content-switcher'

import Clima from "../../../icons/icon/clima.png"
import Bespoke from "../../../icons/icon/new/Frigo_Bespoke.png"
import Frigo75 from "../../../icons/icon/new/Frigo_75.png"
import TV from "../../../icons/icon/new/TV_Pic.png"
import Asciugatrice from "../../../icons/icon/new/Asciugatrice.png"
import Lavatrice from "../../../icons/icon/new/Lavatrice.png"
import Fotovoltaico from "../../../icons/icon/new/Foto.png"
import Induzione from "../../../icons/icon/new/Induzione.png"
import RisparmioBox from '../RisparmioBoxComponent'
import FotoVirtuale from "../../../icons/icon/new/FotoVoltaicoVirtuale.png"

function Prodotto ({ item, key, index, selected, extraMessage }) {
  if (!item.modello || !item.scontato){
    console.log(item)
    return <></>
  }

  const images = {
    "Frigorifero Combinato BESPOKE AI": Bespoke,
    "Frigorifero Combinato F1rst 75 AI": Frigo75,
  }
  let img = images[item.modello]
  if (item.name === "Clima") img = Clima
  if (item.name === "Fotovoltaico") img = Fotovoltaico
  if (item.name === "TV") img = TV
  if (item.name === "Asciugatrice") img = Asciugatrice
  if (item.name === "Lavatrice") img = Lavatrice
  if (item.name === "Induzione") img = Induzione

  return (
    <Col md={12} key={index}>
      <div className='product'>
          <div className='top d-flex justify-content-between'>
            <div className="img-container">
                <img src={img} alt="" role="presentation" />
            </div>
            <div className="product-container" style={{ flexGrow: 1 }}>
              <span className='tag'>{selected.replace("Acqua", "acqua")}</span>
              <div className='product-title'>
                {item.link && item.link.length > 1 ? 
                <a target='_blank' rel='noreferrer'>{item.modello} </a>
                 : 
                 <>{item.modello}</>
                 }
              </div>
              <div className='d-flex justify-content-between light'>
                {item.modello.indexOf("Plug&Play") > -1 ? 
                <div>Consegna bordo strada inclusa</div>
                : <div>Installazione inclusa</div>}
                <div className='qty'>Quantità: {item.qty}</div>
              </div>
            </div>
          </div>
          <div className={ "d-flex bottom flex-column " + (extraMessage ? "justify-content-between" : "justify-content-center") }>
          <div className='d-flex justify-content-between  align-items-center'>
            <div className='light'>
              <b>Totale</b> <br />
              (IVA inclusa)
            </div>
            <div className='price'>
              <div className='final'>{item.scontato} €</div>
              <div className='sub'>
                {/* <span className='green'>Ecobonus {item.ecobonus}&nbsp;</span> */}
                {/* <span className='striked'>{item.costo.replace(",", ".")} €</span> */}
              </div>
            </div>
            </div>
            { extraMessage && <div className='extra-message'>{extraMessage}</div> }
          </div>
          
      </div>
    </Col>
  )
}

const txts = {
  "Clima":"Per migliorare il riscaldamento della tua casa ti suggeriamo di usare un climatizzatore a pompa di calore:",
  "ScaldAcqua":"Per migliorare la produzione di acqua calda della tua casa ti suggeriamo di usare uno scalda acqua a pompa di calore:",
  "Induzione":"Per migliorare la cottura cibi della tua casa ti suggeriamo di usare una piastra ad induzione:",
  "Fotovoltaico":"Per migliorare l'efficienza energetica della tua casa ti suggeriamo di usare un sistema di autoproduzione:"
}

function FinalHeaderComponent () {
  const data = useSelector(get)
  const prodotti = data.products.reduce((acc, item) => {
    if (item.name === "TV" || item.name==="Asciugatrice" || item.name === "Frigo" || item.name === "Lavatrice") {
      if (acc["Elettrodomestici"]){
        acc["Elettrodomestici"].push(item)
      }
      else {
        acc["Elettrodomestici"] = [item]
      }
    }
    else {
    if (acc[item.name]) {
      acc[item.name].push(item)
    } else {
      acc[item.name] = [item]
    }
  }
    return acc
  }, {})
  let startingCategory
  Object.keys(prodotti).forEach(key => {
    if (prodotti[key].length > 0) {
      if(startingCategory === undefined)
        startingCategory = key
      return
    }
  })
  if (data["ConsumoOrario"] === true) prodotti["Offerta luce"] = null
  const [selected, setSelected] = useState(startingCategory) 
  const copy = txts[selected]
  const t = {
    "Clima": "Riscaldamento",
    "ScaldAcqua": "Acqua Calda",
  }
  
  return (
    <div className='header'>
      <Container fluid className='final-header'>
        <Row>
          <Col md={12} className='right-side'>
          <ButtonGroup className='buttons gri-buttons'  role="tablist">
              {Object.keys(prodotti).map((key, index) => (
                <Button
                  role="tab"
                  aria-selected={key === selected}
                  key={index}
                  variant='outline-primary'
                  className={key === selected ? 'active' : ''}
                  onClick={() => setSelected(key)}
                >
                  {key.replace("Acqua", "acqua").replace("Clima", "Riscaldamento").replace("Induzione", "Cottura Cibi")}
                </Button>
              ))}
            </ButtonGroup>
            {/* <MigrationContentSwitcher 
              entries={Object.keys(prodotti).map(key => ({key, label: t[key] ?? key}))} 
              onClick={({ key }) => setSelected(key)}
              preselectedContentKey={selected} /> */}
            {/* {copy && <div id="final-message" className='copy'>{copy}</div>} */}
            <Row className='prodotti'>
              {selected === "Offerta luce" && <Col> <RisparmioBox 
                title="Solare Virtuale"
                description="da 1.0kW"
                tag="Offerta luce"
                image={FotoVirtuale}
                attualeTitle={"Costo iniziale"}
                attualeDescription={"IVA inclusa"}
                attualeValue={"99,00€"}
                futuraTitle={"Abbonamento mensile"}
                futuraDescription={"IVA inclusa"}
                futuraValue={"13 € + CCV 10 €/mese"}
             /></Col>}
              {prodotti[selected]?.map((item, index) => (
                <Prodotto
                  key={index}
                  item={item}
                  index={index}
                  selected={selected}
                  // extraMessage={"Paga il tuo acquisto in 10 comode rate mensili da 39 €"}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FinalHeaderComponent
