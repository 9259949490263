
import React, { useState } from 'react';
import { DsyDivider, DsyIcon } from '../../dsy-wrappers';
import FacSimileComponent from './FacSimileComponent';

import potenza from "../../icons/icon/potenza-consumi-fac.png"
import potenzaContatore from "../../icons/icon/potenza-fac.png"
import spesa from "../../icons/icon/spesa-fac.png"
import BlueBoxComponent from './BlueBoxComponent';

const StdDiv = () => <div className="my-3" aria-hidden="true"><DsyDivider /></div>

const PotenzaTop =() => <div className="f14">
    La <b>potenza del tuo contatore</b> si trova sulla <b>seconda pagina</b> della tua bolletta. In alto a destra, troverai il dettaglio con le caratteristiche tecniche della tua fornitura. Una delle voci è la <b>”potenza contrattualmente impegnata”</b>, esattamente l'informazione che cercavamo!
    <br />
      Se non riesci a trovarla, consulta la <a href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" 
      target="_blank" rel="noreferrer" aria-label="Leggi la guida ufficiale in pdf sul sito Enel Energia, il collegamento apre in una nuova scheda"
      className="color-primary" > guida alla lettura della bolletta </a>

      <img src={potenzaContatore} alt="Esempio bolletta Enel" className="d-block w-100 mt-3" />
</div>
const PotenzaBottom = () => <div className="f14"> 
Segui queste indicazioni per trovare l’informazione sulla tua bolletta.
<StdDiv />
<b>Il valore che ci interessa è la 'potenza del contatore'</b> <br />
Il dato è espresso in <b>kWh</b> (kilowatt) e indica quanta potenza massima il tuo contatore può erogare.
<StdDiv />
<b>Cosa devo cercare?</b> <br />
<ul>
  <li><b>Le pagine 2-3</b></li>
  <li><b>La sezione</b> dedicata ai <b>dettagli del tuo contratto di fornitura elettrica</b> <br /> 
    <b>Parole chiave:</b> "dettagli tecnici della fornitura”, “punto di fornitura”, “dati fornitura”, “dettaglio allegato alla fattura”, “dati e caratteristiche commerciali”;</li>
  <li><b>La dicitura</b> del dato solitamente contiene la parola <b>potenza</b>. <br />
  <b>Parole chiave:</b>  “potenza impegnata”, “potenza contrattualmente impegnata”.</li>
</ul>
<StdDiv />
<BlueBoxComponent icon="info" > <div className="f14 lh14">
<b>Il valore che cerchi</b> è probabilmente <b>3 kW, 4.5 kW, 6 kW</b> o <b>10 kW.</b>
<b>Non confonderti con la “potenza disponibile”</b>, che è generalmente circa il 10% superiore rispetto alla potenza impegnata. Ad esempio, se la potenza del tuo contatore è di 3 kW, la potenza disponibile sarà di circa 3.3 kW.
      </div>
  </BlueBoxComponent>
</div>
const SpesaTop =() => <div className="f14">
La tua <b>spesa annua</b> si trova sulla <b>terza pagina</b> della tua bolletta. Nel secondo box rosso sulla sinistra, chiamato <b>“comunicazioni per la tua offerta e per i tuoi servizi”</b>, troverai la voce <b>“spesa annua”</b>.
<br />
  Se non riesci a trovarla, consulta la <a href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" 
  target="_blank" rel="noreferrer" aria-label="Leggi la guida ufficiale in pdf sul sito Enel Energia, il collegamento apre in una nuova scheda"
  className="color-primary" > guida alla lettura della bolletta </a>

  <img src={spesa} alt="Esempio bolletta Enel" className="d-block w-100 mt-3" />
</div>
const SpesaBottom = () => <div className="f14"> 
Segui queste indicazioni per trovare l’informazione sulla tua bolletta.
<StdDiv />
<b>Il valore che ci interessa è la 'spesa annua'</b> <br />
Il dato è espresso in <b>euro</b> (€) e indica quanto denaro spendi annualmente per la tua fornitura di energia elettrica. 
<StdDiv />
<b>Cosa devo cercare?</b> <br />
<ul>
<li><b>Le pagine 2-3</b></li>
<li><b>La sezione</b> collegata alle <b>informazioni sull'offerta, sulla fatturazione o sui consumi.</b> <br /> 
<b>Parole chiave:</b> "i dati della tua fornitura di energia elettrica", “informazioni relative alla fatturazione”, “consumi e letture”;</li>
<li><b>La dicitura</b> del dato solitamente contiene la parola  <b>annua</b>. <br />
<b>Parole chiave:</b>  “spesa energetica annua”, “spesa annua”, “spesa annua fornitura”, “spesa annua per la bolletta”.</li>
</ul>
<StdDiv />
<BlueBoxComponent icon="info" > <div className="f14 lh14">
Se hai difficoltà a trovare questa informazione in bolletta, puoi <b>sommare l'importo di tutte le bollette</b> dell'ultimo anno o <b>selezionare "Non lo so"</b>.
  </div>
</BlueBoxComponent>
</div>
const ConsumoTop =() => <div className="f14">
      Il tuo <b>consumo annuo</b> si trova sulla <b>seconda pagina</b> della tua bolletta. 
      Sulla sinistra, nella sezione <b>“riepilogo delle letture”</b>, troverai la voce <b>“consumo annuo”</b>. <br />
      Se non riesci a trovarla, consulta la <a href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" 
      target="_blank" rel="noreferrer" aria-label="Leggi la guida ufficiale in pdf sul sito Enel Energia, il collegamento apre in una nuova scheda"
      className="color-primary" > guida alla lettura della bolletta </a>

      <img src={potenza} alt="Esempio bolletta Enel" className="d-block w-100 mt-3" />
  </div>

const ConsumoBottom = () => <div className="f14"> Segui queste indicazioni per trovare l’informazione sulla tua bolletta.
    <StdDiv />
    <b>Il valore che ci interessa è il 'consumo annuo'</b> <br />
    Il dato è espresso in <b>kWh</b> (kilowattora) e indica il quantitativo di kWh utilizzati in un anno a casa tua.
    <StdDiv />
    <b>Cosa devo cercare?</b> <br />
    <ul>
      <li><b>Le pagine 2-3</b></li>
      <li><b>La sezione</b> dedicata ai <b>dettagli dello storico della tua fornitura.</b> <br /> 
        <b>Parole chiave:</b> “consumo annuo”, storico consumi”, “consumi e letture”, “riepilogo consumi” o “consumo fatturato”;</li>
      <li><b>La dicitura</b> del dato solitamente contiene la parola <b>“annuo”</b>. <br />
      <b>Parole chiave:</b> “consumo annuo”, “consumo totale".</li>
    </ul>
    <StdDiv />
    <BlueBoxComponent icon="info" > <div className="f14 lh14">
          Se hai difficoltà a trovare questa informazione in bolletta, puoi <b>sommare i consumi indicati nelle bollette</b> dell'ultimo anno o <b>selezionare "Non lo so"</b>.
          </div>
      </BlueBoxComponent>
   </div>

const componenti = {
  potenzaContatore: {
    top: <PotenzaTop />,
    bottom: <PotenzaBottom />
  },
  consumoAnnuo: {
    top: <ConsumoTop />,
    bottom: <ConsumoBottom />
  },
  spesaAnnua: {
    top: <SpesaTop />,
    bottom: <SpesaBottom />
  }
}

function FacSimileCopyComponent({ facsimile, title, subtitle, copy, image, content }) {
    const [show, setShow] = useState(false)
    return (<div className="fac-simile-copy-component">
            <div className="bold">{ title ?? "Ecco come puoi recuperare questa informazione"}</div>
            <div  className="small-text mb-3">{ subtitle ?? "Nell'esempio qui sotto ti mostriamo dove trovare questo dato."}</div>
            <div className="facsimile"> <div
              className="fonti-facsimile"
              onClick={() => setShow(true)}
              onKeyDown={(e) => { if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) setShow(true) }}
              tabIndex={0}
              role="button"
            >
        <span>Visualizza esempio bolletta </span><DsyIcon library="system" name="arrow-right" size="16px" ></DsyIcon>
        <span className="visually-hidden">Scopri dove trovare questa informazione nella bolletta Enel</span>
      </div></div>
      {show && <FacSimileComponent {...componenti[content]} facsimile={facsimile} show={show} close={() => setShow(false)} copy={copy}  /> }
      </div>
    );
}

export default FacSimileCopyComponent;