import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TV_Pic from "../../icons/icon/new/TV_Pic.png"

import FotoVirtuale from "../../icons/icon/new/FotoVoltaicoVirtuale.png"
import Pompa from "../../icons/icon/pompa.png"
import { useSelector } from 'react-redux';
import {
    get
} from '../../reducers/data';

const images = {
    TV_Pic,
    FotoVirtuale,
    Pompa
}

function BoxComponent({title, serie, description, tags, image, disabled, extraClass, extraTitleCell }) {
    let extraTitle = ""
    const data = useSelector(get);
    if (extraTitleCell && data[extraTitleCell] && extraTitleCell === "User_TV") extraTitle = data[extraTitleCell].split(" ").pop() 
    return (
        <Col>
        <div aria-hidden={ disabled === true} className={"box-component " + (extraClass ?? "") + (disabled ? "disabled" : "") }>
            <div className="d-flex align-items-center mb-3">
                {image && <img src={images[image]} className="me-3 d-none d-lg-block" alt="" role="presentation" />}
                <div className="w-100"> 
                {image && <div className="mb-3 d-flex justify-content-center w-100" style={{ background: "#F7F8FB", borderRadius: "0.5rem"}}>
                    <img src={images[image]} className="me-3 d-block d-lg-none w-100" alt="" role="presentation" /> 
                </div>}
                    
                <div className="title">{title} {extraTitle}</div>
                <div className="serie" dangerouslySetInnerHTML={{ __html: serie}}></div>
                {tags &&<div className="tags">
                    {tags.map((tag, i) => 
                        <div key={i} className="tag">{tag}</div>
                    )}
                </div>}
                </div>
            </div>
            <div className="description" dangerouslySetInnerHTML={{ __html: description}}></div>
        </div>
        </Col>
    );
}

export default BoxComponent;