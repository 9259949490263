

import React, { useState } from 'react';
import InfoModal from './InfoModalComponent';
import InfoPopup from "./InfoPopupComponent"
import info from "../../icons/icon/info2.svg";
import { DsyIcon } from '../../dsy-wrappers';

function InfoButtonComponent(props) {
    const [showInfo, setShowInfo] = useState(false)

    const checkKey = (event) => { 
        if (event.key === "Escape") 
            setShowInfo(false);
    }
    return (<div className={"info-button " + props.extraClass}>
        <button 
        role="dialog"
        // onBlur={() => setShowInfo(false)}
        aria-label={"Apri finestra informativa per " + props.title}
         style={{ border:0, background: "unset"}} 
         onKeyDown={checkKey}
         onClick={() => setShowInfo(!showInfo)} >
                <DsyIcon name="info" size="21px"  title={props.altTitle} alt={props.altTitle ?? "informazioni su " + props.title} id="info" color="#D3135A"> </DsyIcon>
        </button>
        {showInfo && <>
                <InfoModal {...props} keyDown={checkKey} close={() => setShowInfo(false)} />
                <InfoPopup {...props} keyDown={checkKey} close={() => setShowInfo(false)} />
            </>}
            {showInfo &&<button onFocus={() => setShowInfo(false)} className="visually-hidden" aria-hidden="true" ></button>}
            <div className="visually-hidden" aria-hidden="false" aria-live={"polite"} >{showInfo && ("Finestra modale aperta " + props.title)} </div>
        </div>)
}

export default InfoButtonComponent;