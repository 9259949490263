
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { useEffect } from 'react';
import SliderWithThumbComponent from './SliderWithThumbComponent';
import SelectButtonComponent from './SelectButtonComponent';
import InfoButtonComponent from './InfoButtonComponent';
import climaAi from "../../utils/climaai.json"
import { GriToggleComponent } from '../dsy-inhouse/GriToggleComponent';
// import { DsyToggle } from '../../dsy-wrappers';


function OptionalClimaComponent({ name, cell, min, max, step, pre, 
    response, disclaimer,choices, extraClass, 
    info,forceValue,
    cellSiNo, numbertype, optionsDriver, unit }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    let currentValue = data[cell]

    if (currentValue === "No") currentValue = false;

    let suggestedValue
    let shouldRender = (cellSiNo !== undefined && data[cellSiNo] === true)
    if (cellSiNo === undefined) shouldRender= true; 

    if (optionsDriver === "NumeroClimatizzatori" && (data["NumeroClimatizzatori"] === false || data["NumeroClimatizzatori"] === undefined || data["NumeroClimatizzatori"] === "No")){
        suggestedValue = optionsDriver ? 
            choices.filter(x => x.N == Math.min(parseInt(data["C16"]), 9)).map(x => x.name)[0]
            : data[response]
    }
    else {
        if (optionsDriver)
            suggestedValue = choices.filter(x => x.N == data[optionsDriver]).map(x => x.name)[0]
        else if (response === "C16")
            suggestedValue = Math.min(parseInt(data["C16"]), 9)
        else        
            suggestedValue = data[response]
    }

    if (cell === "C42" && !data["C42"] && data["ModificaConfigurazione"] === true){
        dispatch(set({
            cell: "C42",
            value: suggestedValue
        }))
        dispatch(set({
            cell: "ModificaConfigurazione",
            value: true
        }))
    }

    const getChoices = () => { 
        if (data[optionsDriver] !== "No" && data[optionsDriver] !== false) 
            return choices.filter(x => x.N == data[optionsDriver]).map(x => x.name) 
        else 
            return choices.filter(x => x.N == Math.min(parseInt(data["C16"]), 9)).map(x => x.name)
    }

    const onSwitch = (e) => {  const currentVal = data[cellSiNo]
    if (currentVal) // need to remove value
    {
        dispatch(set({
            cell: cell,
            value: undefined
        }))
    }
    else{
        if (cell === "C42"){
        dispatch(set({
            cell: cell,
            value: suggestedValue
        }))
        dispatch(set({
            cell: "ModificaConfigurazione",
            value: true
        }))
    }
    } 
    
    dispatch(set({
        cell: cellSiNo,
        value: data[cellSiNo] !== undefined ? !data[cellSiNo] : true
    }))
}

    return (
        <div className={"slider-component d-flex flex-column " + (extraClass !== undefined ? extraClass:""  )}>
            {pre && <><div className={(pre !== " " ? "mt-16": "")+ " pre"  + (shouldRender === true ? " disabled" : "")}>{pre}</div>
            <div className={"mb-16 mt-16" + (shouldRender === true ? " disabled" : "")}><b >{suggestedValue} {unit}</b></div> </>}
            {cell && <><div className={"slider-component-switch d-flex flex-row flex-lg-row-reverse flex-row align-items-center justify-content-between justify-content-lg-end mb-16 "}>
                <div className={shouldRender === true ? " bold" : ""}>{name}</div>
                <div className="me-md-2">
                    { cellSiNo &&
                    <GriToggleComponent 
                        name={name}
                        onChange={onSwitch}
                        ariaChecked={data[cellSiNo] === true}
                        checked={data[cellSiNo] === true}
                    />
                    //  <DsyToggle variant="apple" 
                    //  aria-checked={data[cellSiNo] === true }
                    //  tabIndex={0}
                    //  dsyValue={data[cellSiNo] === true }
                    //   onKeyDown={(e) => { 
                    //    if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) {
                    //     onSwitch(e)
                    //   }
                    //   }}
                    //   size="small"
                    //   handleChange={onSwitch}
                    //     ></DsyToggle>
                }
                </div>
            </div> </>}
                <SelectButtonComponent extraClass={"flex-grow-1"} name={"Configurazione"} 
                    choices={optionsDriver ? getChoices() : choices} cell={cell} />
                {info && <InfoButtonComponent extraClass="mw40" {...info} />    }
            { disclaimer && <div className="mt-16 mb-16 small-text">{disclaimer}</div> }
        </div>
    );
}



export default OptionalClimaComponent;