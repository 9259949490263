import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from "../icons/storepos.svg"
import circle from "../icons/icon/finalcheck.svg"
import alert from "../icons/icon/Alert.svg"
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { set, get } from '../reducers/data'
import {  DsyIcon } from '../dsy-wrappers';
import BannerImageComponent from './form/banneImage/BannerImageComponent';
import Lottie from "react-lottie-player"
import pigAnimation from "../animations/Maialino-ricco.json"
import { GriButton } from './dsy-inhouse/GriButtonComponent';


function Thanks({ error, enelstore, retry, main }) {
    const dispatch = useDispatch()
    const data = useSelector(get)
    const messageRef = React.createRef();

    useEffect(()=> {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = () => {
            window.history.go(1);
            window.location.href = "/form"
        };

        window.enel_data = {
            page: {
                pageInfo: {
                    pageName: "Simulatore - Thank you page",
                    pageURL: "https://simulatore-risparmio-energia.enelxstore.com/thanks"
                }
            }
        };
        window?._satellite?.track("pageview")
        messageRef.current.focus()
    }, [])

    return (<div className="thanks d-flex flex-column">
          <h2 className="visually-hidden" aria-live="polite" aria-hidden="false" aria-relevant="all" role="banner" ref={messageRef} tabIndex={0}>
                {error ? 
                "Purtroppo al momento non abbiamo una soluzione adatta per le tue esigenze":
                "Il report è stato inviato alla casella email indicata"  }
            </h2>
            <div id="tondo-full" className="d-none d-lg-block"></div>
        <Container fluid>

            <Row>
                <div>
                    <img src={logo} alt="vai alla home page di Enel X store" className='logo' />
                </div>
            </Row>
        </Container>
        <Container className="d-flex justify-content-center align-items-center flex-column">
            <Row>
                <Col lg={6}>
            <main ref={main}>
                {error ? <>
                    <div className="d-flex mx-auto mb-3" style={{ maxWidth: 300, minWidth: 300}}>
                        {/* <Lottie loop={false} animationData={pigAnimation} height={300} width={300} />  */}
                    </div>                   {/* <img src={alert} alt="thanks" /> */}
                    <h2>Wow, hai raggiunto il massimo dell’efficienza energetica! 🌟</h2>
                    <p dangerouslySetInnerHTML={{  __html: error}}></p>
                    <div className="d-flex d-lg-block justify-content-center">
                    <GriButton shape="square" icon-library="material" variant="secondary" onClick={()=> { document.location.href = "/" } } icon-size="right" icon="home" label="Torna alla homepage" > Torna alla homepage</GriButton>
                    </div>
                </>
                :<>
                {data.Error && <p className="visually-hidden">{data.Error}</p>}
                  {/* <img src={data.Error ? alert : circle} alt="thanks" /> */}
                    <h2>{data.Error ? "Siamo spiacenti" : "Evviva! Il report è stato inviato con successo!"}</h2>
                    <p>{data.Error ? "La tua zona non è ancora coperta dal servizio." :<>Un nostro consulente tecnico ti contatterà al più presto. Riceverai una telefonata da uno dei <a aria-label="Apre una nuova scheda con l'elenco dei numeri telefonici utilizzati da ENEL X per il ricontatto" style={{ fontWeight:"bold" }} href="https://www.enelxstore.com/it/it/support-homepage/how-to-buy/telefonate-commerciali/numeri-telefonici-utilizzati-da-enel-x-per-offerte-commerciali" target="_blank" rel="noreferrer" >seguenti numeri.</a>
        <br />
        Controlla la tua casella di posta elettronica, ti abbiamo inviato il risultato della simulazione alla email:  <br />
        <b>{window.localStorage["Email"]}</b> <br /> <br />
        Oppure clicca qui per scaricarlo <br /> <br />
        <a href={data["ProjectUrl"]} target="_blank" rel="noreferrer">
            <GriButton shape="square" 
            icon-library="material" 
            label="Scarica il tuo report"
            variant="secondary" 
            onClick={()=> { 
                document.location.href = data["ProjectUrl"]
            } } 
            icon-size="right" 
            icon="home" >
                Scarica il tuo report
            </GriButton>
        </a>

        </> 
        
        }</p>
                </>}
                {retry === true &&  <a href="https://simulatore-risparmio-energia.enelxstore.com/"><button>CAMBIA COMUNE</button></a>}
            </main>
            </Col>
            <Col lg={6}>
            {error &&   <Lottie loop={false} animationData={pigAnimation} height={300} width={300} /> 
             }
                {/* <BannerImageComponent extraClass="header-image" /> */}
            </Col>
            </Row>

            
        </Container>
    </div>);
}

export default Thanks;