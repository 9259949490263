import {boxes} from './page3Images'

export default function handlePage5(data, pageTitle) {
    switch(pageTitle){
        case "TV": 
        if (data["Final_TV"] === "Si") 
            return [<img src={boxes.grey.tv} style={{}} alt="" role="presentation" />]
        else 
            return [<img src={boxes.big.tv} style={{}} alt="" role="presentation" />]
        case "Frigorifero":
            return [<img src={boxes.big.fridge} style={{}} alt="" role="presentation" />]
        case "Lavatrice":
            return [<img src={boxes.big.wash} style={{}} alt="" role="presentation" />]
        case "Asciugatrice":
            return [<img src={boxes.big.dry} style={{}} alt="" role="presentation" />]
        case "Riscaldamento":
            return [<img src={boxes.big.fire} style={{}} alt="" role="presentation" />]
        case "Autoproduzione energia":
                return [<img src={boxes.big.sun} style={{}} alt="" role="presentation" />]
        case "Potenza contatore":
            
        default: 
            return []

    }
}