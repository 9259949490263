import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import formTemplate from '../../utils/form.js'
import { useDispatch, useSelector } from 'react-redux'
import closeIcon from '../../icons/icon/close.svg'
import { Modal, Container, Row, Button } from 'react-bootstrap'
import RecapchaComponent from 'react-google-recaptcha'
import RiepilogoBlock from './RiepilogoBlockComponent.jsx'
import { set, get, reset } from '../../reducers/data'
import utils from '../../utils/index.js'
import LabelComponent from './LabelComponent.jsx'
import TextComponent from './TextComponent.jsx'
import SelectPopupComponent from './SelectPopupComponent.jsx'
import AcceptComponent from './AcceptComponent.jsx'
import SelectComponent from './SelectComponent.jsx'
import WaitAndThanks from './WaitAndThanks.jsx'
import SelectButtonComponent from './SelectButtonComponent.jsx'
import DsyButton from '../../dsy-wrappers/dsy-button.js'
import { DsyIcon } from '../../dsy-wrappers/dsy-icon.js'
import CAPComponeent from './CAPComponeent.jsx'
import { DsyDivider } from '../../dsy-wrappers/dsy-divider.js'

function Capcha () {
  return <reCAPTCHA />
}

const isEmailValid = email => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/gi.test(email)
const isPhoneValid = phone =>
  /^((00|\+)39[\. ]??)??3\d{2}[\. ]??\d{6,7}$/g.test(phone)

const ContactsModalComponent = ({ show, close, appointment }) => {
  const navigate = useNavigate()
  const submit = async () => {
    try {
      setIsLoading(true)
      window.localStorage['Email'] = data.Email
      const res = await utils.sendLead(data)
      const link = document.querySelector('#download-link')
      dispatch(reset({}))
      link.href = res.ProjectUrl
      link.click()
      navigate('/thanks')
      setIsLoading(false)
    } catch (e) {
      // console.log(e)
      dispatch(
        set({
          cell: 'Error',
          value: e
        })
      )

      navigate('/thanks')
    }
  }

  const dispatch = useDispatch()
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const data = useSelector(get)
  const tr = utils.testRendering
  let formComplete =
    data['Nome'] &&
    data['Cognome'] &&
    // data['Telefono'] &&
    data['Email'] &&
    data["CAP"] && data["CAP"].length === 5 &&
    (!appointment || data['FasciaOraria']) &&
    data['Privacy'] === true &&
    data['Capcha']
  if (data['Email'] && data['Email'].length > 1) {
    if (!isEmailValid(data['Email'])) {
      formComplete = false
      // if (invalidEmail === false) setInvalidEmail(true)
    } else if (invalidEmail === true) setInvalidEmail(false)
  }

  // if (data['Telefono'] && data['Telefono'].length > 8) {
  //   if (!isPhoneValid(data['Telefono'])) {
  //     formComplete = false
  //     // if (invalidPhone === false) setInvalidPhone(true)
  //   } else if (invalidPhone === true) setInvalidPhone(false)
  // }

  let label = 'CONFERMA' //appointment ? "LASCIA I TUOI CONTATTI" : "INVIA"
  if (isLoading) {
    return (
      <>
        <WaitAndThanks loading={true} fs={true} />
        <a
          style={{ display: 'none' }}
          href={data['ProjectUrl']}
          target='_blank'
          rel='noreferrer'
          download='report.pdf'
          id='download-link'
        ></a>
      </>
    )
  }

  const checkFields = () => { 
    if (data['Email'] && data['Email'].length > 1) {
      if (!isEmailValid(data['Email'])) {
        if (invalidEmail === false) setInvalidEmail(true)
      } else if (invalidEmail === true) setInvalidEmail(false)
    }
  
    // if (data['Telefono'] && data['Telefono'].length > 8) {
    //   if (!isPhoneValid(data['Telefono'])) {
    //     if (invalidPhone === false) setInvalidPhone(true)
    //   } else if (invalidPhone === true) setInvalidPhone(false)
    // }
  }

  return (
    <Modal
      show={show}
      aria-modal="true"
      role="dialog"
      aria-label='Ricevi il report'
      centered={true}
      className='select-popup riepilogo contacts'
      animation={false}
      fullscreen={true}
      backdrop={false}
    >
      <Container
        fluid
        className='title d-flex justify-content-between align-items-center'
      >
        <div className='field-name'>
        Ricevi il report e prenota una consulenza
        </div>
        <div className='accent square-button' onClick={close} role="button">
          <img height={14} src={closeIcon} alt="" role="presentation" />
        </div>
      </Container>
      <a
        style={{ display: 'none' }}
        href={data['ProjectUrl']}
        target='_blank'
        rel='noreferrer'
        download='report.pdf'
        id='download-link'
      ></a>

      <Container fluid>
        <div className='d-flex flex-column justify-content-between'>
          <div className="f14 mt-3 mb-4">Compila il form per ricevere il report. Un nostro consulente ti ricontatterà al più presto per approfondire il tuo piano di risparmio' </div>
          <div aria-hidden="true"><DsyDivider /></div>
          <div className="f14 mt-2 mb-4">I campi con * ci servono tutti, non lasciarli vuoti!</div>
          <div className="bold-title">Inserisci il tuo CAP</div>
          <CAPComponeent />
          <div className="bold-title">Indica i tuoi dati di contatto</div>
          <TextComponent name='Nome*' cell='Nome' />
          <TextComponent name='Cognome*' cell='Cognome' />
          
          <TextComponent name='Telefono' cell='Telefono' type='tel' onBlur={checkFields} />
          <div className={invalidPhone ? 'input-error' : "" } aria-live="polite">
            {invalidPhone && <> <DsyIcon name="danger" ></DsyIcon> <span className="ms-2"> Inserisci un numero di telefono valido</span></>}
          </div>
          
          <TextComponent isError={invalidEmail} name='Email*' cell='Email' type='email' onBlur={checkFields} />
          <div className={ invalidEmail ? 'input-error' : ""} aria-live="polite">
            {invalidEmail &&<> <DsyIcon name="danger" ></DsyIcon> <span className="ms-2">  Inserisci un indirizzo email valido</span></>}
          </div>
        
          {appointment === true && (
            <>
              <div className="bold-title mb-0">
              In quale fascia oraria possiamo contattarti?
              </div>
              <SelectButtonComponent
                name='Fascia oraria*'
                cell='FasciaOraria'
                choices={['Non ho preferenze', 'Mattina', 'Pomeriggio']}
                extraClass='fascia'
              />
            </>
          )}

          <AcceptComponent
            extraClass='border bold'
            type='check'
            name='Ho letto e accetto la privacy policy*'
            externalLink={'la privacy policy'}
            ariaLabel="Leggi l'Informativa Privacy, il collegamento apre in una nuova scheda"
            cell='Privacy'
            href='https://www.enelxstore.com/it/it/legals/informativa-privacy'
          />
          <AcceptComponent
            extraClass='border bold'
            type='check'
            name='Do il mio consenso per finalità di marketing (facoltativo)'
            externalLink={'il trattamento dei dati per finalità di marketing'}
            cell='Marketing'
            ariaLabel="Leggi il Trattamento Dati Personali per finalità di marketing, il collegamento apre in una nuova scheda"
            href='https://www.enelxstore.com/it/it/legals/trattamento-dati-personali-per-finalita-di-marketing'
          />
          <AcceptComponent
            extraClass='border bold'
            type='check'
            externalLink={'il trattamento dei dati per finalità di profilazione'}
            name='Do il mio consenso alla profilazione (facoltativo)'
            ariaLabel="Leggi il Trattamento Dati Personali per finalità di profilazione, il collegamento apre in una nuova scheda"
            cell='Profilazione'
            href='https://www.enelxstore.com/it/it/legals/trattamento-dati-personali-per-finalita-di-profilazione'
          />
          <AcceptComponent
            extraClass='border bold'
            type='check'
            externalLink={'il trattamento per finalità di marketing'}
            ariaLabel="Leggi il Trattamento dei Dati Personali per finalità di marketing a terze parti, il collegamento apre in una nuova scheda"
            name='Do il mio consenso per finalità di marketing a terze parti (facoltativo)'
            cell='TerzeParti'
            href='https://www.enelxstore.com/it/it/legals/trattamento-dati-personali-per-finalita-di-marketing-delle-societa-del-gruppo-enel'
          />

        

          <RecapchaComponent
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: '16px 0'
            }}
            sitekey='6Lfq14UmAAAAAIlOFm2Vtd4lI5ng4V-eMImBFzKP'
            onExpired={() =>
              dispatch(
                set({
                  cell: 'Capcha',
                  value: false
                })
              )
            }
            onChange={() =>
              dispatch(
                set({
                  cell: 'Capcha',
                  value: true
                })
              )
            }
          />
        </div>
      </Container>
      <div className='w-100 end-button d-flex align-items-center align-items-lg-start justify-content-lg-between'>
        <div className="w-100 me-3">
          <DsyButton 
          id="cancel-button"
        tabIndex={0}
        label="Annulla"
        aria-label="Annulla"
        uppercase={false}
        full-width={true}  variant="secondary" shape="square"  onClick={close}>
          Annulla
        </DsyButton>
        </div>
        <div className="w-100">
        <DsyButton
          id="confirm-button"
          full-width={true}
          aria-label="Conferma"
          label="Conferma"
          disabled={!formComplete}
          shape="square"
          tabIndex={0}
          uppercase={false}
          onClick={submit}
        >
          Conferma
        </DsyButton>
        </div>
        {/* </div> */}
      </div>
    </Modal>
  )
}

export default ContactsModalComponent
