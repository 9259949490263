import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from "../icons/storepos.svg"

import { useRouteError } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { set, get } from '../reducers/data'
import {  DsyIcon } from '../dsy-wrappers';
import BannerImageComponent from './form/banneImage/BannerImageComponent';
import Lottie from "react-lottie-player"
import pigAnimation from "../animations/Maialino-ricco.json"
import { GriButton } from './dsy-inhouse/GriButtonComponent';


function ErrorPage() {
    const dispatch = useDispatch()
    const data = useSelector(get)
    const messageRef = React.createRef();

    let error = useRouteError();
    console.log(error)

    return (<div className="thanks d-flex flex-column">
            <div id="tondo-full" className="d-none d-lg-block"></div>
        <Container fluid>
            <Row>
                <div>
                    <img src={logo} alt="vai alla home page di Enel X store" className='logo' />
                </div>
            </Row>
        </Container>
        <Container className="d-flex justify-content-center align-items-center flex-column">
            <Row>
                <Col lg={6}>
            <main >
                {error ?? "Qualcosa è andato storto! Ti preghiamo di riprovare più tardi."}        
            </main>
            </Col>
            <Col lg={6}>
            {error &&   <Lottie loop={false} animationData={pigAnimation} height={300} width={300} /> 
             }
                {/* <BannerImageComponent extraClass="header-image" /> */}
            </Col>
            </Row>            
        </Container>
    </div>);
}

export default ErrorPage;