import React, { useState } from "react";

import formTemplate from "../../utils/form.js"
import { useDispatch, useSelector } from 'react-redux';
import closeIcon from "../../icons/icon/close.svg"
import { Modal, Container, Row } from "react-bootstrap";
import RiepilogoBlock from "./RiepilogoBlockComponent.jsx";
import {
    set, get
} from '../../reducers/data';
import utils from "../../utils/index.js"

const RiepilogoModalComponent = ({ show, close }) => {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const tr = utils.testRendering

    return (
        <Modal show={show} 
        aria-modal="true"
        role="dialog"
        aria-label="Visualizza Riepilogo"
            centered={true} className="select-popup riepilogo" animation={false} fullscreen={true} backdrop={false}>
            <Container fluid className="title d-flex justify-content-between align-items-center" >
                <div className="field-name">Riepilogo</div>
                <div className="accent" tabIndex={0} onClick={close}>Chiudi <img height={14} src={closeIcon} alt="x close" /></div>
            </Container>
            
            <Container fluid className={"mb-5"} >
                {formTemplate.pages.filter((x,i) => x && (i === 3 ||tr(x, data))).map((section, index) => <RiepilogoBlock key={index} page={section} index={index} />)}
            </Container>
        </Modal>
    )
}

export default RiepilogoModalComponent;