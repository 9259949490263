import TextComponent from "./TextComponent"
import NumberComponent from "./NumberComponent"
import SelectComponent from "./SelectComponent"
import LabelComponent from "./LabelComponent"
import SwitchComponent from "./SwitchComponent"
import SliderComponent from "./SliderComponent"
import OptionalComponent from "./OptionalComponent"
import { useSelector } from 'react-redux';
import {
    get
} from '../../reducers/data';
import SelectAtViewComponent from "./SelectAtView"
import MultiSelectAtViewComponent from "./MultiSelectAtViewComponent"
import PercentageComponent from "./PercentageComponent"
import AddressCompleteComponent from "./AddressCompleteComponent"
import RisparmioPreliminareComponent from "./RisparmioPreliminareComponent"
import SelectAtViewFotoComponent from "./SelectAtViewFotoComponent"
import utils from "../../utils/index.js"
import RiepilogoComponent from "./RiepilogoComponent"
import AddressAltComponent from "./AddressAltComponent"
import SelectButtonComponent from "./SelectButtonComponent.jsx"
import StepperComponent from "./StepperComponent.jsx"
import AddressCAPCityComponent from "./AddressCAPCityComponent.jsx"
import OptionalClimaComponent from "./OptionalClimaComponent.jsx"
import ConfiguraRiscaldamentoComponent from "./ConfiguraRiscaldamentoComponent.jsx"
import BoxComponent from "./BoxComponent.jsx"
import SelectAtViewBoxComponent from "./SelectAtViewBox.jsx"
import { DsyDivider } from "../../dsy-wrappers/dsy-divider.js"
import FacSimileCopyComponent from "./FacSimileCopy.jsx"
import BoxFotoVirtualeComponent from "./BoxFotoVirtualeComponent.jsx"
const testRendering = utils.testRendering

function FormPage({ items, riepilogo = true }) {
    const data = useSelector(get);

    // console.log("ENTERING FORM PAGE")
    // const testRendering = (item) =>{
    //     //props are in OR
    //     for (let i = 0; i < item.conditionalRendering.length; i++) {
    //         const element = item.conditionalRendering[i];
    //         // console.log(data[element.cell], element.value)
    //         if (element.operator === "notequal" && data[element.cell] !== element.value) return true;
    //         if (element.operator === "equal" && data[element.cell] === element.value) return true;
    //     }
    //     return false
    // }

    const mapComponent = (item, ) => {
        if (item.conditionalRendering && testRendering(item, data) === false) return null;

        const k = item.cell ?? item.name
        // if (k === undefined)
        //     debugger
        
        switch(item.type){
            case "spacer": return <div className={"spacer mb-32 " + item.extraClass } key={k}></div>
            case "divider": return <div className="my-4"  aria-hidden="true"><div className={"divider-parent " + (item.extraClass ??  "")}> <DsyDivider key={k} /></div></div>
            case "facsimile": return <FacSimileCopyComponent {...item} key={k} />
            case "choiceatviewfoto":
                return <SelectAtViewFotoComponent {...item} key={k} />
            case "box": 
                return <BoxComponent {...item} key={k} />
            case "summary": 
                return <RisparmioPreliminareComponent {...item} key={k} />
            case "address":
                return <AddressCAPCityComponent {...item} key={k} />
            case "choiceatviewbox": 
                return <SelectAtViewBoxComponent {...item} key={k} />
            case "multichoice": 
                return <MultiSelectAtViewComponent {...item} key={k} />
            case "percentage": 
                return <PercentageComponent {...item} key={k} />
            case "choiceatview":
                return <SelectAtViewComponent {...item} key={k} />
            case "optional":
                return <OptionalComponent {...item} key={k} />
            case "optionalclima":
                return <OptionalClimaComponent {...item} key={k} />
            case "slider": 
                return <SliderComponent {...item} key={k} />
            case "stepper":
                return <StepperComponent {...item} key={k} />
            case "bool":
                return <div className={item.extraClass ?? "" } key={k}><SwitchComponent {...item} key={k} /></div>
            case "label":
                return <LabelComponent {...item} key={k} />
            case "boxfotovirtuale": 
                return <BoxFotoVirtualeComponent />
            case "text":
                return <div className={"mb-16 " + item.extraClass ?? "" } key={k}><TextComponent {...item} /></div>
            case "riscaldamento"
                : return <div className={"mb-16 " + item.extraClass ?? ""} key={k}><ConfiguraRiscaldamentoComponent {...item} /></div>
            case "number":
                return <div className={"mb-16 " + item.extraClass ?? ""} key={k}><NumberComponent {...item} /></div>
            case "choice":
                return <div className={"mb-16 " + item.extraClass ?? ""} key={k}><SelectComponent {...item} /></div>
                case "choicebuttons":
                    return <div className={"mb-16 " + item.extraClass ?? ""} key={k}><SelectButtonComponent {...item} /></div>
            default: return <div>TODO: {item.type}</div>
        }
    }
    // col-lg-7 px-0"
    return (<div className="form-page-component row" >
        {items.map((x, i) => mapComponent({...x, index: i}))}

        {riepilogo !== false && <RiepilogoComponent />}
    </div>);
}

export default FormPage;