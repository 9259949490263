import utils from "../utils/index.js"
import optionsClima from "./climaai.json"
const sendProject = utils.sendProject
const sendExtra = utils.sendProjectFinal
const sendFoto = utils.sendFoto

const data = {
    "pages": [
        {
            "title": "Raccontaci della tua casa",
            "image": "italy",
            "items": [
                {
                    "name":"Indirizzo",
                    "type":"address",
                    "cell": "Indirizzo",
                    "desktopPage":1
                },
                {
                    "type":"divider",
                    "desktopPage": 1,
                },
                {
                    "name": "Piccola o spaziosa? Storica o moderna?",
                    "description": "Condividi con noi qualche dettaglio sulla tua casa",
                    "type": "label",
                    "component": "h2",
                    "extraClass": "mb-16 mt-0",
                    "desktopPage": 1
                },
                {
                    "name": "Tipologia edificio",
                    "cell": "C11",
                    "type": "choicebuttons",
                    "choices": [
                        "Monofamiliare",
                        "Bifamiliare/Villette a schiera",
                        "Piccolo Condominio (2-8 abitazioni)",
                        "Medio Condominio (9-15 abitazioni)",
                        "Grande Condominio (oltre 16 abitazioni)"
                    ],
                    "desktopPage": 1
                },
                {
                    "name":"È presente un impianto fotovoltaico",
                    "cell": "PresenzaFotovoltaico",
                    "type": "bool",
                    // "conditionalRendering": 
                    // [
                    //         {
                    //             "cell": "C11",
                    //             "operator": "equal",
                    //             "value": "Monofamiliare"
                    //         },
                    //         {
                    //             "cell": "C11",
                    //             "operator": "equal",
                    //             "value": "Bifamiliare/Villette a schiera"
                    //         }
                    // ],
                    "desktopPage": 1
                },
                  {
                    "name":"Sono presenti uno o più balconi",
                    "cell": "PresenzaBalcone",
                    "type": "bool",
                    "announceWhen": true,
                    "announceMessage":"Nuovo componente disponibile per la selezione dei balconi",
                    "desktopPage": 1
                },
                 {
                    "name": "Tipologia balconi",
                    "cell": "TipologiaBalconi",
                    "type": "choicebuttons",
                    "choices": [
                        "Ringhiera",
                        "Muratura"
                    ],  
                    "conditionalRendering": 
                        [
                             {
                                 "cell": "PresenzaBalcone",
                                 "operator": "equal",
                                 "value": true
                             }
                        ],
                    "desktopPage": 1
                },
                {
                    "name":"Casa ristrutturata negli ultimi 10 anni",
                    "cell": "RistrutturatoUltimi10Anni",
                    "extraClass": "",
                    "type": "bool",
                    "info": {
                        "title":"Cosa intendiamo per ristrutturazione della casa",
                        "subtitle":"Per <b>'casa'</b> intendiamo <b>l'immobile in cui il proprietario e i membri del suo nucleo familiare risiedono.</b> Può trattarsi di una casa, un appartamento, una villa o qualsiasi altro tipo di <b>edificio residenziale.</b> <br />  <br /> In questo contesto, una <b>ristrutturazione si riferisce a interventi eseguiti negli ultimi 10 anni</b> che migliorano <b>l'efficienza energetica</b> dell'immobile.  <br />  <br />Questo include lavori eseguiti all'interno dell'abitazione, come la <b>sostituzione degli infissi</b> con modelli a doppio o triplo vetro, l'installazione di <b>impianti di riscaldamento a condensazione</b> o <b>pompe di calore</b>, nonché <b>l'isolamento delle pareti e dei tetti</b> per ridurre le dispersioni termiche. <br />  <br />A livello esterno, si considerano <b>interventi sulla facciata dell'edificio</b>, come l'applicazione di un <b>cappotto termico</b> o altre forme di <b>coibentazione</b>."
                    },
                    "announceWhen": 
                        { 
                            true: "Nuovo componente disponibile per la selezione della coibentazione dell'edificio",
                            false: "Nuovo componente disponibile per la selezione dell'anno di costruzione dell'edificio"
                        },
                    "desktopPage": 1
                },
                {
                    "name":"Condominio/edificio coibentato",
                    "description": "La coibentazione consiste in interventi che isolano l’edificio impedendo scambi di calore che possono generare dispersione termica.",
                    "cell": "Coibentato",
                    "type": "bool",
                     "conditionalRendering": 
                        [
                             {
                                 "cell": "RistrutturatoUltimi10Anni",
                                 "operator": "equal",
                                 "value": true
                             }
                        ],
                        "desktopPage": 1
                },
                {
                    "name": "Anno di costruzione",
                    "cell": "C10",
                    "type": "choicebuttons",
                    "above":true,
                    "extraClass":"mt-3",
                    "choices": [
                        "Non so",
                        "Prima del 1919 e non ristrutturata di recente",
                        "1919-1945 e non ristrutturata di recente",
                        "1946-1960 e non ristrutturata di recente",
                        "1961-1980 e non ristrutturata di recente",
                        "1981-1990 o recentemente ristrutturata senza coibentazione",
                        "1991-2005",
                        "Dopo il 2005 o ristrutturata con coibentazione"
                    ],
                    "conditionalRendering": [{
                        "cell": "RistrutturatoUltimi10Anni",
                        "operator": "notequal",
                        "value": true
                    }],
                    "desktopPage": 1
                },
                {
                    "name": "Classe energetica",
                    "cell": "C36",
                    "above":true,
                    "type": "choicebuttons",
                    "choices": [
                        "Non so",
                        "A4",
                        "A3",
                        "A2",
                        "A1",
                        "B",
                        "C",
                        "D",
                        "E",
                        "F",
                        "G"
                    ],
                    "desktopPage": 1
                },
                {
                    "name": "A che piano si trova?",
                    "description": "Sei al piano terra con i gatti nel cortile o più in alto ad ammirare gli uccelli?",
                    "extraClass": "mb-sm-16 mt-sm-0",
                    "component":"h2",
                    "cell": "C12",
                    "type": "choiceatview",
                    "choices": [
                    {
                        "text": "Ultimo piano",
                        "image":2,
                        "value":"Ultimo piano"
                    },
                    {
                        "text": "Piano intermedio",
                        "image": 1,
                        "value":"Piano intermedio"
                    },
                   {
                        "text": "Piano terra",
                        "image": 0,
                        "value":"Piano terra"
                    }], 
                    "conditionalRendering": 
                    [
                         {
                             "cell": "C11",
                             "operator": "equal",
                             "value":  "Piccolo Condominio (2-8 abitazioni)",
                         },
                          {
                             "cell": "C11",
                             "operator": "equal",
                             "value":  "Medio Condominio (9-15 abitazioni)",
                         },
                         {
                            "cell": "C11",
                            "operator": "equal",
                            "value":   "Grande Condominio (oltre 16 abitazioni)",
                        }
                    ],
                    "desktopPage": 2
                },
                {
                    "name": "Quanto è grande (metri quadri)?",
                    "placeholder": "Dimensione casa (metri quadri)",
                    "cell": "C13",
                    "type": "slider",
                    "unit": "m2",
                    "min": 20,
                    "max": 500,
                    "info": {
                        "title":"Dimensione della tua casa",
                        "subtitle":"<b>La dimensione minima consentita dalla normativa per un'abitazione</b>, comprensiva dei servizi (come bagni e cucine), <b>non può essere inferiore a 20 metri quadri.</b> <br /> <br />Se invece possiedi <b> una casa superiore a 500 metri quadri, seleziona comunque 500</b>: il nostro algoritmo effettuerà una simulazione adeguata e precisa."
                    },
                    "extraClass": "",
                    "markers": [
                        "28",
                        "100",
                        "200",
                        "300",
                        "400",
                        "500"
                    ],
                    "desktopPage": 3
                },
                {
                    "type":"divider",
                    "desktopPage": 3,
                },
                {
                    "name": "Quanti locali ha?",
                    "placeholder": "Locali totali",
                    "description": "Puoi selezionare fino ad un massimo di 20 locali",
                    "info": {
                        "title":"Cosa si intende per locali?",
                        "subtitle":"Per locali si intendono spazi come <b>camere da letto, studi</b> e <b>saloni</b>, mentre sono <b>esclusi i servizi</b>, come i bagni, le cucine e altre aree funzionali della casa."
                    },
                    "cell": "C16",
                    "type": "stepper",
                    "min": 1,
                    "step": 1,
                    "max": 20,
                    "defaultValue":1,
                    "text":"Locali",
                    "icon": "casa",
                    "extraClass": "mt-16",
                    "markers": [
                        "1",
                        "20"
                    ],
                    "desktopPage": 3
                },
                {
                    "type":"divider",
                    "desktopPage": 3,
                },
                {
                    "name": "Quante persone ci vivono in totale?",
                    "placeholder": "Persone totali",
                    "description":"Puoi selezionare fino ad un massimo di 20 inquilini",
                    "cell": "C15",
                    "type": "stepper",
                    "min": 1,
                    "defaultValue":1,
                    "step": 1,
                    "extraClass": "mt-16",
                    "max": 20,
                    "text":"Inquilini",
                    "icon": "inquilini",
                    "markers": [
                        "1",
                        "20"
                    ],
                    "desktopPage": 3
                },
                // {
                //     "name": "Quanti mesi all’anno è abitata la casa?",
                //     "cell": "C17",
                //     "type": "stepper",
                //     "step": 1,
                //     "icon": "mesi",
                //     "text": "Mesi",
                //     "min": 1,
                //     "max": 12,
                //     "defaultValue":12,
                //     "markers": [
                //         "1",
                //         "12"
                //     ],
                   
                //     "desktopPage": 5
                // },
                // {
                //     "name": "La casa è prevalentemente abitata nel periodo invernale",
                //     "cell": "C18",
                //     "type": "bool",
                //     "conditionalRendering": 
                //     [
                //          {
                //              "cell": "C17",
                //              "operator": "notequal",
                //              "value": "12"
                //          }
                //     ],
                //     "desktopPage": 5
                // },
                {
                    "type": "label",
                    "name": "Come rendi più fresca la tua casa in estate?",
                    "desktopPage": 4
                },
                {
                    "name": "Ho dei climatizzatori",
                    "cell": "C19",
                    "type": "bool",
                    "info": {
                        "title":"Cosa intendiamo per climatizzatori",
                        "subtitle":"Quando ti chiediamo se hai un climatizzatore a casa, ci riferiamo a un apparecchio che utilizza un'<b>unità esterna (il motore) collegata a uno o più un’unità interne (gli split)</b>. Questi dispositivi sono progettati per raffreddare o riscaldare l'aria, mantenendo una temperatura confortevole in casa. <br /> <br /> <b>Non ci riferiamo a deumidificatori, ventilatori, o altri dispositivi che semplicemente muovono l'aria o riducono l'umidità</b> senza modificare la temperatura in modo significativo."
                    },
                    "announceWhen": true,
                    "announceMessage":"Nuovo componente disponibile per la funzione di riscaldamento",
                    "component": "h2",
                    "desktopPage": 4
                },
               
                {
                    "name": "I climatizzatori hanno anche funzione riscaldamento",
                    "cell": "CondizionatoriRiscaldamento",
                    "type": "bool",
                    // "disableIf": {
                    //     "cell": "C19",
                    //     "operator":"equal",
                    //     "value": false
                    // },
                    "conditionalRendering":[{
                        "cell": "C19",
                        "operator":"equal",
                        "value": true
                    }],
                    "desktopPage": 4
                },
                {
                    "type": "divider",
                    "desktopPage": 4,
                },
                {
                    "name": "Hai elettrodomestici più vecchi di 10 anni?",
                    "cell": "EV",
                    "type": "multichoice",
                    "choices": [
                        {
                            "text": "Frigorifero",
                            "image": 4,
                            "cell": "Frigorifero"
                        },
                        {
                            "text": "Lavatrice",
                            "image": 5,
                            "cell": "Lavatrice"
                        },
                        {
                            "text": "Asciugatrice",
                            "image": 6,
                            "cell": "Asciugatrice"
                        },
                        {
                            "text": "TV",
                            "image": 7,
                            "cell": "TV"
                        },
                    ],
                    "conditionalRendering": {
                        "cell": "process.env.REACT_APP_PRODOTTI_SAMSUNG",
                        "operator": "equal",
                        "value": "true"
                    },
                    "required": false,
                    "desktopPage": 4
                }  
            ]
        },
        {
            "title": "Le tue fonti di energia",
            "items": [
                {
                    "name": "Il riscaldamento è centralizzato",
                    "cell": "C24",
                    "type": "bool",
                    "component": "h2",
                    "announceWhen": false,
                    "announceMessage":"Nuovo componente disponibile per la selezione della fonte energetica utilizzata per il riscaldamento",
                    "desktopPage": 1
                },
                {
                    "type": "label",
                    "name": "Quali fonti energetiche utilizzi?",
                    "description": "La storia della tua casa ci sta appassionando!",
                    "desktopPage": 1,
                    "component": "h2",
                    "extraClass": "mt-0"
                },
                {
                    "name": "Seleziona fonte riscaldamento",
                    "cell": "C25",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Gasolio",
                        "Pellet",
                        "Legna secca",
                        "Elettrico"
                    ],
                    "conditionalRendering": [{
                        "cell": "C24",
                        "operator": "notequal",
                        "value": true
                    }],
                    "desktopPage": 1
                },
                {
                    "name": "Seleziona fonte acqua calda",
                    "cell": "C26",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Gasolio",
                        "Pellet",
                        "Legna secca",
                        "Elettrico"
                    ],
                    "desktopPage": 1
                },
                {
                    "name": "Seleziona fonte cottura cibi",
                    "cell": "C27",
                    "type": "choicebuttons",
                    "choices": [
                        "Metano",
                        "GPL",
                        "Aria Propanata",
                        "Elettrico"
                    ],
                    "desktopPage": 1
                },
                // {
                //     "name": "Ho il serbatoio GPL interrato",
                //     "cell": "C28",
                //     "type": "bool",
                //     "conditionalRendering": 
                //     [
                //          {
                //              "cell": "C27",
                //              "operator": "equal",
                //              "value": "GPL"
                //          },
                //          {
                //             "cell": "C26",
                //             "operator": "equal",
                //             "value": "GPL"
                //         },
                //         {
                //             "cell": "C25",
                //             "operator": "equal",
                //             "value": "GPL"
                //         }
                //     ],
                //     "desktopPage": 1
                // },
                {
                    "name": "C'è il solare termico",
                    "cell": "C29",
                    "type": "bool",
                    "component": "h2",
                    "desktopPage": 1,
                    "info": {
                        "title":"Cosa intendiamo per solare termico",
                        "subtitle":"Il solare termico è un sistema che <b>sfrutta l'energia del sole per riscaldare l'acqua</b>. I pannelli solari, solitamente montati sui <b>tetti</b>, catturano la luce solare e la <b>convertono in calore</b>, che viene poi utilizzato per scaldare l'acqua."
                    }
                }, 
                // {
                //     "name": "Conosci la potenza del tuo contatore?",
                //     "cell": "C61",
                //     "type": "bool",
                //     "info": {
                //         "title":"Non conosci la tua attuale potenza?",
                //         "subtitle":"La potenza (kW) è un dato facoltativo. Se non conosci la tua potenza (kW) attiva l’opzione “non la conosco” e procedi con la simulazione. Utilizzeremo un valore di potenza medio come valore di calcolo. "
                //     },
                //     "desktopPage": 2
                // }, 
                {
                    "name": "Qual è la potenza del tuo contatore?",
                    "facsimile": {
                        "image": 0,
                        "content": "potenzaContatore",
                        "title":"Ecco come puoi recuperare questa informazione",
                        "subtitle": "Nell'esempio qui sotto ti mostriamo dove trovare questo dato.",
                        "html": `<div >
                         <b> Pagina 2 della bolletta</b> <br>
                         Se non riesci a trovare le informazioni, consulta la <b><a aria-label="Leggi la guida ufficiale in pdf sul sito Enel Energia, il collegamento apre in una nuova scheda" href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" target="_blank"> guida alla lettura della bolletta</a></b>
                        </div>`
                    },
                    "type": "slider",
                    "cell": "D61",
                    "unit": "kW",
                    "min": 3,
                    "max": 10,
                    "step": 0.5,
                    "defaultValue": 3,
                    "component": "h2",
                    "cellSiNo": "C61",
                    "cellSiNoCopy": {
                        title:"Se non sai la potenza, ci pensiamo noi!",
                        subtitle:"Se non riesci a trovare questa informazione, puoi selezionare l’opzione “Non lo so” e saltare questo passaggio. Utilizzeremo il valore di potenza più diffuso (3 kW)."
                    },
                    "info": {
                        "title":"Non conosci la tua attuale potenza?",
                        "subtitle":"La potenza è una informazione facoltativa. Se non la conosci, seleziona l’opzione “Non lo so” e procedi con la simulazione. Utilizzeremo il valore di potenza più diffuso (3 kW)"
                    },
                    "sr": "Non conosco la potenza del mio contatore",
                    // "conditionalRendering":[{
                    //     "cell": "C61",
                    //     "operator": "equal",
                    //     "value": true
                    // }],
                    "disableIf": {
                        "cell": "C61",
                        "operator": "equal",
                        "value": "No"
                    },
                    // "extraClass": "force-mobile",
                    // "canBeNull":"true",
                    "desktopPage": 2
                }, 
               
              
            ]
        },
        {
            "title": "Capiamo quanta energia usi in un anno!",
            "items": [
                {
                    "type": "label",
                    "name": "<h2 class='fonti-text bold-title'>Quanto spendi ogni anno per le varie fonti energetiche?</h2>",
                    "desktopPage": 1,
                    "extraClass":"no-margin"     
                },
                {
                    "type": "percentage",
                    "name": "",
                    "cell": "C31",
                    // "subtitle": "Senza canone RAI",
                    "canBeNull": true,
                    // "after": "Se non riesci a trovare tutte le informazioni sulla tua spesa puoi selezionare l'opzione \"Non la conosco\" e saltare questo passaggio. Utilizzeremo una stima basata sulle informazioni relative all'abitazione.",
                    "desktopPage": 1                    
                },
              
               
                {
                    "name": "Indica il consumo elettrico annuo in kWh",
                    "type": "slider",
                    "unit": "kWh/a",
                    "cell": "C35",
                    "min": 0,
                    "max": 15000,
                    "step": 100,
                    "info": {
                        "title":"Non conosci il tuo consumo elettrico annuo?",
                        "subtitle":"Il consumo elettrico (kWh/a) è un dato facoltativo. Se non conosci il tuo consumo disattiva l'opzione \"Conosco il mio consumo elettrico annuo\" e procedi con la simulazione. Utilizzeremo una stima basata sulle informazioni relative all'abitazione"
                    },
                    "sr": "Non conosco il mio consumo energetico annuo",
                    "cellSiNoCopy": {
                        title:"Se non sai il consumo, ci pensiamo noi!",
                        subtitle:"Se non riesci a trovare tutte le informazioni sul tuo consumo, puoi selezionare l’opzione “Non lo so” e saltare questo passaggio. Utilizzeremo una stima basata sulle informazioni relative all’abitazione."
                    },
                    // "conditionalRendering":[{
                    //     "cell": "C34",
                    //     "operator": "equal",
                    //     "value": true
                    // }],
                    "facsimile": {
                        "type":"facsimile",
                        "content":"consumoAnnuo",
                        "title":"Ecco come puoi recuperare questa informazione",
                        "subtitle": "Nell'esempio qui sotto ti mostriamo dove trovare questo dato.",
                        "desktopPage":2,
                        "image": 2
                       },
                    "disableIf": {
                         "cell": "C34",
                         "operator": "equal",
                         "value": "No"
                    },
                    "cellSiNo": "C34",
                    "canBeNull":"true",
                    "desktopPage": 2
                },
                
                //    {
                //     "name": "Conosco il consumo elettrico annuo dell'abitazione",
                //     "cell": "C34",
                //     "type": "bool",
                //     "desktopPage": 2
                // }, 
            ],
            "onSubmit": sendProject
        },
        {
            "title": "Ecco quanto puoi risparmiare",
            "showBack": false,
            "nextText": "Continua",
            "items": [{
                "type":"summary",
                "desktopPage": 1,
                "riepilogo": false
            }]
        },
        {
            "title": "Configura come vuoi in base alle tue esigenze",
            "hideSubtitleMobile": true,
            "items": [
                // {
                //     "type": "label",
                //     "name": "Per che cosa vorresti ricevere un'offerta?",
                //     "desktopPage": 1,
                //     "extraClass": "d-lg-none no-margin my-3"     
                // },
                {
                    "type": "label",
                    "component": "h2",
                    "name":"Per che cosa vorresti ricevere un'offerta?",
                    "desktopPage": 1,
                    "extraClass": "no-margin my-3"
                },
                {
                    "type": "multichoice",
                    "name": "",
                    "required": true,
                    "choices": [
                        {
                        "text": "Riscaldamento",
                        "image": 0,
                        "cell": "C41",
                        "risp":"Risc_Risparmio",
                        "conditionalRendering":[{
                            "cell": "C24",
                            "operator": "notequal",
                            "value": true
                        }, 
                        {
                            "cell": "C25",
                            "operator": "notequal",
                            "value": "Elettrico"
                        }]
                    },
                    // {
                    //     "text": "Acqua calda sanitaria",
                    //     "image": 1,
                    //     "cell": "C45",
                    //     "risp":"AC_Risparmio",
                    //     "conditionalRendering":[
                    //     {
                    //         "cell": "C26",
                    //         "operator": "notequal",
                    //         "value": "Elettrico"
                    //     }]
                    // },
                    {
                        "text": "Piano Cottura",
                        "image": 8,
                        "cell": "C48",
                        "risp":"Cott_Risparmio",
                        "conditionalRendering":[
                            {
                                "cell": "ShowC48",
                                "operator": "must",
                                "value": true
                            },
                            {
                                "cell": "process.env.REACT_APP_PRODOTTI_SAMSUNG",
                                "operator": "must",
                                "value": "true"
                            },
                            {
                                "cell": "C27",
                                "operator": "notequal",
                                "value": "Elettrico"
                            }]
                    },
                    {
                        "text": "Lavatrice",
                        "image": 5,
                        "cell": "Final_Lavatrice",
                        "risp":"Lavatrice",
                        "conditionalRendering":[
                            {
                                "cell": "Lavatrice",
                                "operator": "equal",
                                "value": "Si"
                            }]
                    },
                    {
                        "text": "TV",
                        "image": 7,
                        "cell": "Final_TV",
                        "risp":"TV",
                        "conditionalRendering":[
                            {
                                "cell": "TV",
                                "operator": "equal",
                                "value": "Si"
                            }]
                    },
                    {
                        "text": "Asciugatrice",
                        "image": 6,
                        "cell": "Final_Asciugatrice",
                        "risp":"Asciugatrice",
                        "conditionalRendering":[
                            {
                                "cell": "Asciugatrice",
                                "operator": "equal",
                                "value": "Si"
                            }]
                    },
                    {
                        "text": "Frigorifero",
                        "image": 4,
                        "cell": "Final_Frigorifero",
                        "risp":"Frigorifero",
                        "conditionalRendering":[
                            {
                                "cell": "Frigorifero",
                                "operator": "equal",
                                "value": "Si"
                            }]
                    },
                    {
                        "text": "Energia solare",
                        "image":3,
                        "cell": "C53"
                    },
                    {
                        "text": "Energia solare",
                        "image":3,
                        "cell": "FotoVirtuale",
                        "conditionalRendering": [
                            {
                                "cell": "process.env.REACT_APP_FOTO_VIRTUALE",
                                "operator": "equal",
                                "value": "true"
                            },
                            {
                                "cell": "C11",
                                "operator": "notin",
                                "value": [     
                                    "Piccolo Condominio (2-8 abitazioni)",
                                    "Medio Condominio (9-15 abitazioni)",
                                    "Grande Condominio (oltre 16 abitazioni)"
                                ]
                            },
                             {
                                 "cell": "PresenzaBalcone",
                                 "operator": "equal",
                                 "value": "No"
                             }
                        ]
                    },
                ],
                    "desktopPage": 1                    
                },
            ],
            "onCheck": sendFoto
        },
        {
            "title": "Riscaldamento",
            "page": 4,
            "conditionalRendering": 
                    [
                         {
                             "cell": "C41",
                             "operator": "equal",
                             "value": true
                         }
                    ],
            "items":[
                // {
                //     "type":"label",
                //     "name": "In base alle informazioni fornite sull'abitazione, ti proponiamo:",
                //     "desktopPage": 1,
                //     "extraClass":"mb-0"
                // },
                {
                    "type": "riscaldamento",
                    "desktopPage": 1
                },
               
                
             
                
                {
                    "name": "",
                    "extraClass": "my-16",
                    "type": "spacer",
                    "desktopPage": 1   
                },
                {
                    "name": "Qual'è l'investimento più adatto a te?",
                    "type": "label",
                    "desktopPage": 1   
                },
                {
                    "name": "Opzioni di investimento?",
                    "cell": "PrestazioneRiscaldamento",
                    "type": "choicebuttons",
                    "above":true,
                    "choices": [
                        "Più economico €",
                        "Miglior rapporto qualità/prezzo €€",
                        "Top di gamma €€€"
                    ],
                    "desktopPage": 1   
                }
            ]
        },
        {
            "title": "Acqua calda",
            "page": 4,
            "conditionalRendering": 
            [
                 {
                     "cell": "C45",
                     "operator": "equal",
                     "value": true
                 }
            ],
            "items":[
                {
                    "type": "label",
                    "name":"Tipo di soluzione <br />In base alle informazioni fornite sull'abitazione ti proponiamo:",
                    "cell": "",
                    "component": "h2",
                    "desktopPage": 1
                },
                {
                    "type": "label",
                    "name": "<div><b>{cell} Scaldacqua a pompa di calore </b> </div>",
                    "cell": "C46",
                    "extraClass":"mt-0 scaldaquabox",
                    "desktopPage": 1
                },
                    {
                    "name": "troverai il dettaglio nel report finale",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "mt-0"
                }, 
            
                
            
            ]
        },
            {
                "title": "TV",
                "page": 4,
                "conditionalRendering": 
                [
                     {
                         "cell": "Final_TV",
                         "operator": "equal",
                         "value": true
                     }
                ],
                "items": [
                    {
                        "name": "Guardare una serie tv non è mai stato così bello!",
                        "type": "label",
                        "desktopPage": 1,
                        "extraClass": "no-margin mb-16"
                    }, 
                    {
                        "type": "box",
                        "extraTitleCell": "User_TV",
                        "extraClass": "yellow-tags",
                        "title":"TV Samsung Crystal UHD",
                        "serie":"Serie 8",
                        "tags": ["Processore Crystal 4k", "LED"],
                        "description":`<b>Il processore Crystal 4K</b> ti permette di godere di ogni sfumatura di colore, garantendo <b>immagini vivide</b> e <b>realistiche</b>. Con OTS Lite, <b>il suono ti immerge nell'azione</b>, facendoti sentire al centro di ogni scena. 
                                       Grazie a Motion Xcelerator, <b>l'esperienza di gioco</b> diventa straordinaria, con <b>movimenti fluidi e reattivi</b>. E con <b>Smart Hub</b>, hai tutti i tuoi contenuti preferiti raccolti in un unico posto, rendendo l'<b>accesso rapido e semplice.</b>`,
                        "desktopPage": 1,
                        "image": "TV_Pic",
                        "required": false
                    },
                    {
                    "type": "choiceatview",
                    "cell":"User_TV",
                    "desktopPage": 1,
                    "name":"Seleziona la dimensione della TV",
                    "horizontal": "horizontal",
                    "choices": [
                    {
                        "text": "43\"",
                        "value":"Crystal UHD 43\""
                    },
                    {
                        "text": "55\"",
                        "value":"Crystal UHD 55\""
                    },
                   {
                        "text": "65\"",
                        "value":"Crystal UHD 65\""
                    }], 
                }]
            }
            ,
            {
                "title": "Frigorifero",
                "page": 4,
                "conditionalRendering": 
                [
                     {
                         "cell": "Final_Frigorifero",
                         "operator": "equal",
                         "value": true
                     }
                ],
                "items": [
                    {
                        "name": "In base alle informazioni fornite sull’abitazione, ti proponiamo:",
                        "type": "label",
                        "desktopPage": 1,
                        "extraClass": "no-margin"
                    }, 
                    {
                        "type": "choiceatviewbox",
                        "cell":"User_Frigo",
                        "desktopPage": 1,
                        "extraClass": "yellow-tags",
                        "choices": [
                        {
                                "image":"Frigo_75",
                                "text": "Frigorifero Combinato F1rst 75 AI",
                                "subtitle": "Dimensioni: 75,9 x 203 x 71,1 cm",
                                "tags": ["Classe energetica A", "538L"],
                                "value":"Frigorifero Combinato F1rst 75 AI",
                                "description": "Un modello extra large da 538 litri offre una <b>capacità interna superiore</b>. Il sistema Twin Cooling Plus™ <b>ottimizza temperatura</b> e <b>umidità</b>, conservando gli alimenti in condizioni ideali e <b>preservandone il sapore naturale.</b> Con SmartThings, puoi gestire i consumi energetici, ridurre le emissioni di CO2 e risparmiare. L'AI Energy Mode consente di <b>abbattere i consumi fino al 15%*</b>. Il design elegante in nero aggiunge un tocco moderno alla cucina, mentre il rivestimento interno in acciaio trattiene il freddo più a lungo."
                        },
                        {
                            "image":"Frigo_Bespoke",
                            "text": "Frigorifero Combinato BESPOKE AI",
                            "subtitle": "Dimensioni: 59,5 x 203 x 65,8 cm",
                            "tags": ["Classe energetica A -10%", "387L"],
                            "value":"Frigorifero Combinato BESPOKE AI",
                            "description": "Con un design moderno e una <b>profondità standard</b> di <b>600 mm</b>, si integra perfettamente con gli altri mobili della cucina per un aspetto elegante e armonioso. <b>I moduli personalizzabili</b> ti permettono di adattare il frigo al tuo stile di vita. La classe energetica <b>A-10%**</b> garantisce un <b>significativo risparmio energetico.</b> La tecnologia All-Around Cooling assicura una <b>refrigerazione uniforme</b>, mentre il compressore Digital Inverter è silenzioso e duraturo. Il cassetto Humidity Fresh+ <b>mantiene freschi frutta e verdura</b>, e il sistema Metal Cooling trattiene il freddo compensando le perdite di calore."
                        },
                       
                        ]},
                    {
                        "type": "divider",
                        "desktopPage": 1,
                        "extraClass": "lg"
                    }, 
                    {
                        "type":"label",
                        "desktopPage": 1,
                        "extraClass":"lg",
                        "name": "<div class='description'>* I risultati del test si basano sul confronto tra la temperatura come da impostazioni di fabbrica con modalità AI Energy e senza modalità AI Energy. I risultati possono variare a seconda delle condizioni e delle modalità di utilizzo. <br />  <br /> ** La gamma di frigoriferi Samsung offre modelli dotati di tecnologie avanzate come il sistema di isolamento Air Space e il compressore Digital Inverter garantito 20 anni, che fanno risparmiare fino al 10% in più rispetto alla classe A. </div>"
                    }
                    
                ]
            }
        ,
        // {
        //     "title": "Cottura cibi",
        //     "subtitle":"Piano cottura a induzione",
        //     "page": 4,
        //     "conditionalRendering": 
        //     [
        //          {
        //              "cell": "C48",
        //              "operator": "equal",
        //              "value": true
        //          }
        //     ],
        //     "items":[
        //         {
        //             "pre": "In base alle informazioni fornite sull'abitazione ti proponiamo:",
        //             // "unit":"Piano di cottura a induzione libera",
        //             "name": "Voglio modificare la configurazione",
        //             "placeholder": "",
        //             "cellSiNo": "C48Test",
        //             "cell": "D48_val",
        //             "response": "D48",
        //             "type": "optional",
        //             "canBeNull": true,
        //             "desktopPage": 1,
        //             "disclaimer": "*Potrebbe essere necessario effettuare successivi approfondimenti al fine di identificare la migliore configurazione possibile per il tuo edificio (se ci sono stanze comunicanti, vincoli ambientali o tecnici).",
        //             "choices": ["59.2cm","60cm", "75cm"]   
        //         },   
        //         // {
        //         //     "name": "Livello di prestazione",
        //         //     "cell": "PrestazioneCottura",
        //         //     "type": "choice",
        //         //     "choices": [
        //         //         "Più economico",
        //         //         "Miglior rapporto qualità/prezzo €€",
        //         //         "Top di gamma €€€"
        //         //     ],
        //         //     "conditionalRendering": 
        //         //     [
        //         //          {
        //         //              "cell": "C123132DaCambiare",
        //         //              "operator": "notequal",
        //         //              "value": false
        //         //          }
        //         //     ],
        //         //     "desktopPage": 1   
        //         // }
        //     ]
        // },
        {
            "title": "Autoproduzione energia",
            "page": 4,
            // "notCondo": true,
            "conditionalRendering": 
            [
                {
                    "cell": "C11",
                    "operator": "notin",
                    "value": ["Monofamiliare", "Bifamiliare"]
                },
                 {
                     "cell": "C53",
                     "operator": "equal",
                     "value": true
                 },
                 {
                    "cell": "C54",
                    "operator": "equal",
                    "value": true
                }
            ],
            "items":[
                {
                    "name": "Puoi scegliere la tipologia di fotovoltaico che preferisci:",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "no-margin lg"
                }, 
                {
                    "type": "choiceatviewbox",
                    "cell":"User_Foto",
                    "desktopPage": 1,
                    "choices": [
                    {
                        "image":"Foto_Batteria",
                        "text": "Pannelli solari con batteria",
                        "subtitle": "fotovoltaico da tetto",
                        "tags": ["Impianto da: 3kW / 4kW / 6kW "],
                        "value":"C54",
                        "description": "L’impianto fotovoltaico con sistema di accumulo (batteria) è una soluzione completa. Ideale per chi è alla ricerca di semplicità e convenienza, consente di accumulare l'energia solare prodotta e non consumata durante il giorno per utilizzarla anche quando l'impianto non è in funzione, ad esempio nelle ore notturne."
                    },
                    {
                        "image":"Foto_Solare",
                        "text": "Pannelli solari",
                        "subtitle": "fotovoltaico da tetto",
                        "tags": ["Impianto da: 3kW / 4kW / 6kW "],
                        "value":"C53",
                        "description": "Ideale per chi è alla ricerca di semplicità e convenienza, consente di risparmiare sulla bolletta grazie all'autoconsumo. L'offerta è completa di tutti gli accessori necessari al funzionamento e comprende installazione, gestione delle pratiche amministrative e smaltimento dei moduli a fine vita."
                    },
                    ]},
                {
                    "name": "Quale livello di prestazione desideri?",
                    "type": "label",
                    "desktopPage": 1   
                },
                {
                    "name": "Livello di prestazione",
                    "cell": "PrestazionePennelli",
                    "type": "choicebuttons",
                    "above":true,
                    "choices": [
                        "Più economico €",
                        "Miglior rapporto qualità/prezzo €€",
                        "Top di gamma €€€"
                    ],
                    "desktopPage": 1   
                }
            ]
        },
        {
            "title": "Solare Virtuale",
            "page": 4,
            "conditionalRendering": 
            [
                {
                    "cell": "FotoVirtuale",
                    "operator": "equal",
                    "value": true
                }
            ],
            "items":[
                {
                    "name": "Non puoi installare un pannello fotovoltaico? Abbiamo una soluzione per te!",
                    "type": "label",
                    "desktopPage": 1,
                    "extraClass": "no-margin lg mb-3"
                }, 
                {
                     "type": "boxfotovirtuale",
                     "desktopPage": 1,
                }                    
            ]
        },
        {
            "title": "La potenza del tuo contatore",
            "page": 5,        
            "items":[
                {
                    "box": true,
                    "name": "Scegli la nuova potenza del tuo contatore",
                    "placeholder": "",
                    "cellSiNo": "C84",
                    "cell": "D84",
                    "response": "C82",
                    "type": "optional",
                    "step": 0.5,
                    "min": 3,
                    "max": 10,
                    "numbertype": "input",
                    "numberCell":"D84",
                    "unit": "kW",
                    "canBeNull": true,
                    "desktopPage": 1   
                }, 
            ],
            "onSubmit": sendExtra,
        },
        {
            "title": "Fine",
            "items": []
        }
    ]
}

export default data;