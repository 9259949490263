import React from 'react';
import { DsyDivider, DsyIcon } from '../../dsy-wrappers';
import BlueBoxComponent from './BlueBoxComponent';
import InfoButtonComponent from './InfoButtonComponent';

function Box ({power, description}) { 
    return <div className="box">
        <div className="power">{power} kW</div>
        <div className="description">{description}</div>
    </div>
}

function AumentoContatoreComponent({ from, to, extraClass }) {

    if (from === to ) return <>
     <div className={"aumento-contatore "+ extraClass }>
            <div className="bold-title mb-3">In base alla previsione dei tuoi nuovi consumi, ti suggeriamo di non cambiare la potenza del tuo contatore:</div>
            <div className="d-flex align-items-center">
                <Box power={from} description={"Poternza attuale"} />
            </div>
            <div className="mt-5 mb-3 mw730" aria-hidden="true"><DsyDivider /></div>
        </div></>
    return (
        <>
        <div className="aumento-contatore">
            <div className="bold-title mb-3">In base alla previsione dei tuoi nuovi consumi, ti suggeriamo la nuova potenza del tuo contatore:</div>
            <div className="d-flex align-items-center">
                <Box power={from} description={"Poternza attuale"} />
                <div className="mx-3"> <DsyIcon name="arrowForward" /></div>
                <Box power={to} description={"Nuova potenza suggerita"} />
                <InfoButtonComponent title="Perché ti proponiamo una nuova potenza?" 
                subtitle="È probabile che tu abbia scelto per la tua casa nuove soluzioni o reso più efficienti quelle esistenti. <b>Per garantirne il corretto funzionamento, è necessario adeguare la potenza del contatore</b>, così da gestire il carico energetico ed evitare interruzioni. <br /> <br /> Questo intervento, qualora ci fosse <b>bisogno di un incremento energetico sostanziale, </b> potrebbe richiedere il cambio del contatore stesso. Ma non preoccuparti, nel caso, sarai supportato da un nostro tecnico." />
                {/* <a target="_blank" rel="noreferrer" 
                aria-label="Apre una nuova scheda con ulteriori informazioni sulla lettura della bolletta della luce"
                href="https://vivielettrico.it/it/gestione-energia-casa/energia-elettrica/leggere-la-bolletta-della-luce" ><DsyIcon name="info" color="#D3135A" className="ml-3" /></a> */}
            </div>
        </div>

        <BlueBoxComponent extraClassName="mt-4"> 
        Se richiedi un <b>aumento della potenza del contatore</b> dovrai pagare un <b>contributo una tantum </b>(cioè solo una volta) definito dal venditore in base al contratto. Inoltre, sarà previsto un <b>aumento dei costi ricorrenti in bolletta.</b> Sia il contributo una tantum che i costi ricorrenti in bolletta verranno cosiderati, nel risultato di questa simulazione, con <b>valori medi*</b><br /> <br />
        <div className="d-flex color-secondary align-items-center" ><a  target="_blank" rel="noreferrer" 
                aria-label="Apre una nuova scheda con ulteriori informazioni sulla lettura della bolletta della luce"
                href="https://vivielettrico.it/it/gestione-energia-casa/energia-elettrica/aumento-di-potenza">
        Costi ricorrenti in bolletta &nbsp;</a><DsyIcon name="arrow-right" size="14px" /></div>
        </BlueBoxComponent>

        <div className="mt-5 mb-3 mw730" aria-hidden="true"><DsyDivider /></div>
        <div className="bold-title">
            Vuoi impostare la nuova potenza in autonomia?
        </div>

        <div className="gen-subtitle ">
        Se il nostro suggerimento sulla nuova potenza del tuo contatore non ti convince, <br /> puoi modificarla come preferisci. 
        </div>
        </>
    );
}

export default AumentoContatoreComponent;