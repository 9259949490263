import React from 'react';
import { DsyIcon } from '../../dsy-wrappers';
import electricMeter from "../../icons/icon/electric-meter.svg"
import infoFilled from "../../icons/icon/info_filled.svg"


function BlueBoxComponent({icon, extraClassName, children  }) {
     return (
        
        <div className={"blue-box w-100 mw-100 d-flex justify-content-center align-items-center " + extraClassName} >
            <div className="me-3">
                {icon === "info" && <img src={infoFilled} alt="" role="presentation" /> }
                {icon === undefined  && <img src={electricMeter} alt="" role="presentation" /> }
                {/* <DsyIcon name={icon} color="blue"  size="24px"  />  */}
            </div>
            <div>
                {children}
            </div>
        </div>
        
    );
}

export default BlueBoxComponent;