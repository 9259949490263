
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { useState, useRef } from 'react';
import SelectPopupComponent from './SelectPopupButtonsComponent';
import arrow from "../../icons/icon/dropdownarrow.svg"
import   { components  } from 'react-select'
import AsyncSelect from "react-select/async"

import muratura from "../../icons/buttons/muratura.svg"
import ringhiera from "../../icons/buttons/ringhiera.svg"

import monofamiliare from "../../icons/buttons/monofamiliare.svg"
import bifamiliare from "../../icons/buttons/bifamiliare.svg"
import piccolo from "../../icons/buttons/piccolo.svg"
import medio from "../../icons/buttons/medio.svg"
import grande from "../../icons/buttons/grande.svg"

import metano from "../../icons/buttons/metano.svg"
import gpl from "../../icons/buttons/gpl.svg"
import aria from "../../icons/buttons/aria.svg"
import gasolio from "../../icons/buttons/gasolio.svg"
import pellet from "../../icons/buttons/pellet.svg"
import legna from "../../icons/buttons/legna.svg"
import elettrico from "../../icons/buttons/elettrico.svg"

import check from "../../icons/buttons/check.svg"
import { DsyIcon, DsyInputDropdown } from '../../dsy-wrappers';

import più  from "../../icons/buttons/piu_economico.svg"
import miglior  from "../../icons/buttons/miglior_rapporto_qualita_prezzo.svg"  
import top from "../../icons/buttons/top_di_gamma.svg"

const imgs = {
    muratura,
    ringhiera,
    monofamiliare,
    bifamiliare,
    piccolo,
    medio,
    grande,
    metano,
    gpl,
    aria,
    gasolio,
    pellet,
    legna,
    elettrico,
    più,
    miglior,
    top
}


function SelectButtonComponent({ name, cell, choices, extraClass, index, disabled, above }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const [open, setOpen] = useState(false)
    const currentValue = data[cell]
    const [ariaFocusOption, setAriaFocusOption] = useState(undefined);  
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = (e) => setIsMenuOpen(false);
    const onFocus= ({ focused, isDisabled }) => {
        const msg = `Stai selezionando ${focused.label}${
          isDisabled ? ', disabilitata' : ''
        }`;
        // setAriaFocusOption(focused.label);
        return msg;
      };
    

    const newChoices = choices.map((x, i) => { return { value: x, label: x }})
    
const Control = ({ children, ...props }) => {
    const value = props.getValue()
    return (<components.Control aria-hidden={true} {...props}     
         className={ value.length === 0 ? 'empty-select-control': 'main-select-control'}>
        {children} 
    </components.Control>)
}

const DropdownIndicatorArrow = (
    props
  ) => {
    return (
    <div aria-hidden={true} className="dropdown-new">
        <img src={arrow} alt="" role="presentation" />
    </div>
    );
};

const CustomOption = (props) => {
    const { data, isSelected, innerProps } = props
    const { tabIndex: _, ...rest } = innerProps;

    let icon = imgs[data.value.toLowerCase().split("/")[0].split(" ")[0]]
    if (!icon)
        icon = imgs[data.value.toLowerCase().split(" ")[0]]

    const condo = ["Piccolo Condominio (2-8 abitazioni)", "Medio Condominio (9-15 abitazioni)", "Grande Condominio (oltre 16 abitazioni)"]

    let className = "non-selected-opt"
    if (isSelected) className = "selected-opt"
    if (ariaFocusOption === data.label) className += " focused"

    if (icon)
    return (
        <components.Option {...props} innerProps={{
            ...rest,
            role: "option",
            "aria-selected": isSelected
          }}>
            <div
                className={className }
                style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "space-between",
                padding: "4px",
                    // height: "48px",
                width: "100%",
                position: "relative",
                zIndex: 2147483647
                }}
            >
                <div className="w-full d-flex justify-content-center align-items-center" >
                    <img src={icon} alt="" className="option-icon" />
                    {condo.includes(data.label) ?
                    <div className='d-flex flex-column'>
                        <div className="small-text mb-0">{data.label.split("(")[0]} </div>
                        <div className="my-0">{data.label.split("(")[1].replaceAll(")", "")}</div>
                    </div> 
                    :data.label.replaceAll("€", "")}

                </div>
                {isSelected && 
                <DsyIcon name="check" className="check-freccia" size="16px" color="#D3135A" aria-hidden={true} /> 
                // <img src={check} className="check-freccia" alt="" />
                }
            </div>
        </components.Option>
    );
    else return  <components.Option {...props}
    innerProps={{
        ...rest,
        role: "option",
        "aria-selected": isSelected
      }}>
            <div
                className={className }
                style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "space-between",
                padding: "4px",
                    // height: "48px",
                width: "100%",
                position: "relative",
                zIndex: 2147483647
                }}
            >
            <div className="w-full d-flex justify-content-center align-items-center" >
                {data.label}
            </div>
            {isSelected &&
             <DsyIcon name="check" className="check-freccia" size="16px" color="#D3135A" aria-hidden={true} /> 
            // <img src={check} className="check-freccia" alt="" />
            }
        </div>
    </components.Option>

};

const openModal = (e) => {
    if (disabled === true) return
    window.scrollTo(0,0); 
    setOpen(true)
    e.preventDefault()
}

const iconMapping = {
    "Monofamiliare": "home",
    "Bifamiliare/Villette a schiera": "holiday_village",
    "Piccolo Condominio (2-8 abitazioni)": "villa",
    "Medio Condominio (9-15 abitazioni)":"corporate_fare",
    "Grande Condominio (oltre 16 abitazioni)":"apartment",
    "Ringhiera": "width_normal",
    "Muratura": "deployed_code",
    "Metano": "gas_meter",
    "GPL":"valve",
    "Aria Propanata":"air_purifier_gen",
    "Gasolio":"propane",
    "Pellet": "local_fire_department",
    "Elettrico": "electric_meter",
    "Legna secca": "fireplace",
    "Più economico €": "star",
    "Miglior rapporto qualità/prezzo €€": "star_half",
    "Top di gamma €€€": "star_rate"
}

const items = newChoices.map(x => {
    const label = x.label.replaceAll("€", "").trim()
    return {
        labelContent: label,
        showLeftIcon: true,
        leftIconLibrary: 'material',
        leftIconSize: iconMapping[x.label] ? '16px ' : "0px",
        leftIconName: iconMapping[x.label] ?? "none",
        isSelected: data[cell] === label,
    }
  })

    return (<>
        <div
            onClick={openModal}
            aria-expanded={open}
            aria-label={"Combo box per" + name + (currentValue ? currentValue + " selezionato" : "")}
            onKeyDown={(e) => { 
                if (disabled === true) return; 
                if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) return openModal()
                e.preventDefault() }}
            tabIndex={0}
            className={"select-component d-flex d-lg-none flex-column input-border justify-content-center " + (data ? "filled " : "")+ (extraClass ? extraClass : "")} >
            <div>
                {!currentValue ? <div aria-hidden={true} className="fieldname">{name}</div> :
                    <div className="select-with-value" aria-hidden={true}>
                        <div className="prop-name">{name}</div>
                        <div className="prop-value">{currentValue?.replaceAll(" e non ristrutturata di recente","")
                                                                    ?.replaceAll(" o recentemente ristrutturata senza coibentazione")
                                                                    ?.replaceAll(" o ristrutturata con coibentazione", "")
                                                                    ?.replaceAll("undefined", "") }</div>
                    </div>}
            </div>

            <img src={arrow} className="open-drawer" width={16} alt="" role="presentation" />
        </div>
        <div className={"d-none d-lg-block select-box " + (extraClass ? extraClass : "") }>
      
            <div style={{ maxWidth: "430px"}} >
             {/* <DsyInputDropdown 
                renderListAbove={above ?? false}
                label={name} 
                tabIndex={0}
                exclusive-selection={true} 
                entries={items} 
                closesOnSelection={true}
                isOpen={open}
                onFocus={  () => dispatch(set({
                    cell: "focus",
                    value: cell
                  })) }
                onBlur={  () => dispatch(set({
                    cell: "focus",
                    value: undefined
                  })) }
                onKeyDown={(e) => { console.log(e); if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) setOpen(!open);}}
                handleChange={(e, ev) =>  {
                    dispatch(set({ cell: cell, value: e }))
                    }}
                />   */}
                <AsyncSelect  
                        role="combobox"
                        className={currentValue ? 'main-select-control ' : 'empty-select-control '}
                        aria-live="polite"
                        defaultOptions={newChoices}
                        onKeyDown={(e) => { if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) 
                            setIsMenuOpen(true); 
                            e.stopPropagation();
                         }}
                        aria-label={name}
                        isSearchable={false}
                        isClearable={false}
                        isDisabled={disabled === true}
                        placeholder={<span aria-hidden={true}>{name}</span>}
                        value={data[cell] ? {value: data[cell], label: data[cell]} : null}
                        menuIsOpen={isMenuOpen}
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuClose}
                        cacheOptions={true}
                        onFocus={  () => dispatch(set({
                            cell: "focus",
                            value: cell
                          })) }
                        onBlur={  () => dispatch(set({
                            cell: "focus",
                            value: undefined
                          })) }
                        styles={{ paddingTop: currentValue ? 10 : 0, 
                            option: (base, state) => ({
                            ...base,
                            height: "48px",
                            display:"flex",
                            alignItems: "center",
                            color: state.isSelected ? '#5738ff' : 'black',
                            background: state.isFocused ? '#EFF2F7' : 'transparent', // TODO scegli el color al posto de grey
                          }) }}
                        onChange={(e) => {
                            dispatch(set({ cell: cell, value: e.value }))
                            // const focusableElements = document.querySelectorAll(
                            //     'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
                            // );
                            // const currentFocus = Array.prototype.indexOf.call(focusableElements, document.activeElement);
                            // setTimeout(() => focusableElements[currentFocus+1].focus(), 100)
                        }}
                        ariaLiveMessages={{
                            guidance: () => 'Premi le frecce su e giù per selezionare le opzioni',
                            onChange: () =>  name + " selezionato: " + data[cell],
                            onFilter: () => newChoices.length + ' opzioni disponibili',
                            onFocus
                        }}
                        menuPlacement={ choices?.find(x => x === "Top di gamma €€€") ? "top": "bottom"}
                        minMenuHeight={300}
                        components={{ 
                            DropdownIndicator: DropdownIndicatorArrow, 
                            Option: CustomOption, 
                            // Control: Control 
                        }} 
                        />
            </div>
            {currentValue && <div  className="select-box-text">{name?.replaceAll(" e non ristrutturata di recente","")
                      ?.replaceAll(" o recentemente ristrutturata senza coibentazione")
                      ?.replaceAll(" o ristrutturata con coibentazione", "")
                      ?.replaceAll("undefined", "")  }</div>}
        </div> 

        <SelectPopupComponent name={name} show={open} close={() => setOpen(false)} choices={choices} cell={cell} />
    </>
    );
}

export default SelectButtonComponent;
