import React from 'react';
import FinalHeaderComponent from './final/FinalHeaderComponent';
import BeneficiFinaliComponent from './final/BeneficiFinaliDesktopComponent';
import ReviewOrDownload from './final/ReviewOrDownloadComponent';
import BookAppointment from './final/BookAppointmentComponent';
import ContactsModalComponent from './ContactsModalComponent';
import { Row, Col, Container} from  "react-bootstrap";
import logo from '../../icons/storepos.svg'
import BannerImageComponent from './banneImage/BannerImageComponent';

function RisparmioFinaleComponent({main}) {
const [showModal, setShowModal] = React.useState(false);
const [showAppointment, setShowAppointment] = React.useState(false);
return (<div className="final d-none d-lg-flex px-0 mx-0">
    <div className="d-flex w-100">
        <Col md={4} id="left-banner" as="header" className="d-flex" >
            <div id="tondo" className="d-none d-lg-block" style={{zIndex: 0}}></div>
            <div className="sticky logo d-flex flex-column">
                <img src={logo} alt="vai alla home page di Enel X store" />
                <h1>Scopri cosa abbiamo in serbo per te!</h1>
                <BannerImageComponent extraClass="d-none d-lg-block" />
            </div>
        </Col>
        <Col md={8} id="right-banner" as="main" ref={main}>
            <div style={{ maxWidth: "884px", margin: "0 auto", paddingLeft: "3rem", paddingRight: "3rem"}}>
                <div id="title">La soluzione energetica per la tua casa</div>
                <div className="gen-subtitle">Ecco tutti i prodotti che abbiamo scelto per te: meno energia consumata, più risparmio in bolletta.</div>
                <FinalHeaderComponent />
                <BeneficiFinaliComponent />
                <ReviewOrDownload changeModal={(val)=> setShowModal(val)} />
                <ContactsModalComponent appointment={true} show={showAppointment} close={() => setShowAppointment(false)} />
                <ContactsModalComponent appointment={false} show={showModal} close={() => setShowModal(false)} />
            </div>
            <BookAppointment changeModal={(val) => setShowAppointment(val)} />
        </Col>
        </div>
    </div>
)
}

export default RisparmioFinaleComponent

