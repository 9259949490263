
import { useSelector, useDispatch } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, { Children } from 'react';
import checkedIcon from "../../icons/buttons/radio-button-on.svg"
import uncheckedIcon from "../../icons/icon/radio-empty.svg"

import muratura from "../../icons/buttons/muratura_text.svg"
import ringhiera from "../../icons/buttons/ringhiera_text.svg"

import monofamiliare from "../../icons/buttons/monofamiliare_text.svg"
import bifamiliare from "../../icons/buttons/bifamiliare_text.svg"
import piccolo from "../../icons/buttons/piccolo_text.svg"
import medio from "../../icons/buttons/medio_text.svg"
import grande from "../../icons/buttons/grande_text.svg"

import metano from "../../icons/buttons/metano_text.svg"
import gpl from "../../icons/buttons/gpl_text.svg"
import aria from "../../icons/buttons/aria_text.svg"
import gasolio from "../../icons/buttons/gasolio_text.svg"
import pellet from "../../icons/buttons/pellet_text.svg"
import legna from "../../icons/buttons/legna_text.svg"
import elettrico from "../../icons/buttons/elettrico_text.svg"

import mattina from "../../icons/buttons/mattina.svg"
import pomeriggio from "../../icons/buttons/pomeriggio.svg"
import non from "../../icons/buttons/non.svg"

import a4 from "../../icons/buttons/a4.svg"
import a3 from "../../icons/buttons/a3.svg"
import a2 from "../../icons/buttons/a2.svg"
import a1 from "../../icons/buttons/a1.svg"
import b from "../../icons/buttons/b.svg"
import c from "../../icons/buttons/c.svg"
import d from "../../icons/buttons/d.svg"
import e from "../../icons/buttons/e.svg"
import f from "../../icons/buttons/f.svg"
import g from "../../icons/buttons/g.svg"

import a4s from "../../icons/buttons/a4s.png"
import a3s from "../../icons/buttons/a3s.png"
import a2s from "../../icons/buttons/a2s.png"
import a1s from "../../icons/buttons/a1s.png"
import bs from "../../icons/buttons/bs.png"
import cs from "../../icons/buttons/cs.png"
import ds from "../../icons/buttons/ds.png"
import es from "../../icons/buttons/es.png"
import fs from "../../icons/buttons/fs.png"
import gs from "../../icons/buttons/gs.png"

import top from "../../icons/buttons/top_text.svg"
import miglior from "../../icons/buttons/miglior_text.svg"
import piu from "../../icons/buttons/piu_text.svg"

import ceRect from "../../icons/buttons/ce-rect.svg"
import { DsyIcon } from '../../dsy-wrappers';

const imgs = {
    muratura,
    ringhiera,
    monofamiliare,
    bifamiliare,
    piccolo,
    medio,
    grande,
    metano,
    gpl,
    aria,
    gasolio,
    pellet,
    legna,
    elettrico,
    a4,
    a3,
    a2,
    a1,
    b,
    c,
    d,
    e,
    f,
    g,
    a4s,
    a3s,
    a2s,
    a1s,
    bs,
    cs,
    ds,
    es,
    fs,
    gs,
    top,
    miglior,
    piu,
    mattina,
    pomeriggio,
    non
}

const iconMapping = {
    "Monofamiliare": "home",
    "Bifamiliare/Villette a schiera": "holiday_village",
    "Piccolo Condominio (2-8 abitazioni)": "villa",
    "Medio Condominio (9-15 abitazioni)":"corporate_fare",
    "Grande Condominio (oltre 16 abitazioni)":"apartment",
    "Ringhiera": "width_normal",
    "Muratura": "deployed_code",
    "Metano": "gas_meter",
    "GPL":"valve",
    "Aria Propanata":"air_purifier_gen",
    "Gasolio":"propane",
    "Pellet": "local_fire_department",
    "Elettrico": "electric_meter",
    "Legna secca": "fireplace",
    "Più economico €": "star",
    "Miglior rapporto qualità/prezzo €€": "star_half",
    "Top di gamma €€€": "star_rate"
}

const fascia = {
    "Mattina": "dalle 9:00 alle 13:00",
    "Pomeriggio": "dalle 13:00 alle 19:00",
    "Non ho preferenze": "dalle 9:00 alle 19:00"
}

function RenderOptionComponent ({ name, cell, action }) {
    const dispatch = useDispatch();
    const data = useSelector(get);
    const checked = data[cell] === action 

    const onChangeHandler =  (action) => {
        if  (data[cell] === action) dispatch(set({cell: cell, value: undefined}))
        else dispatch(set({cell: cell, value: action}))
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32) {
            onChangeHandler(action) 
        }
        if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
            e.currentTarget.previousElementSibling?.focus();
        }
        if (e.key === "ArrowRight" || e.key === "ArrowDown") {
            e.currentTarget.nextElementSibling?.focus();
        }
        if (e.key !== "Tab") 
            e.preventDefault();
    }

    let text = action + "";
    text = text.replaceAll("€", "")
    if (cell === "C10")
        text = text?.replaceAll(" e non ristrutturata di recente", "")
        ?.replaceAll(" o recentemente ristrutturata senza coibentazione", "")
        ?.replaceAll(" o ristrutturata con coibentazione", "")
    
    const [text1, text2] = text.split("(")

    if (cell === "C36" && action !== "Non so")
        return  <div tabIndex={0} 
                        role="radio" 
                        aria-checked={checked}
                        className={"d-flex  align-items-center select-option px-3 " + (checked ? "sel" : "")}
                        onKeyDown={handleKeyDown}
                        aria-label={text}
                        onClick={() => onChangeHandler(action)}>
                        <div className="mr-4" aria-hidden={true}>
                            <img width={20} src={checked ? checkedIcon : uncheckedIcon} alt="" />
                        </div>
                            <div aria-hidden={true} className={"main "  }>{text.split("(")[0]}</div>
                            <img alt=""
                            role="presentation"
                            className="opt-image" src={imgs[text.split("/")[0].split(" ")[0].toLowerCase()]} />
                    </div>
    else if (cell === "C10"  || cell === "C42" || cell === "FasciaOraria" || (cell === "C36" && action === "Non so"))
        return  <div tabIndex={0} 
            role="radio" 
            aria-checked={checked}
            className={"d-flex align-items-center select-option px-3 " + (checked ? "sel" : "")}
            onKeyDown={handleKeyDown}
            aria-label={text}
            onClick={() => onChangeHandler(action)}>
            <div className="mr-4" aria-hidden={true} >
                <img src={checked ? checkedIcon : uncheckedIcon} alt="" width={20} />
            </div>
            <div aria-hidden={true}>
                <div className={"main "  }>{text.split("(")[0]}</div>
                {text.split("(")[1] && <div className="sub">{text.split("(")[1].replaceAll(")", "")}</div>}
                {cell === "FasciaOraria" && <div className="sub">{fascia[text]} </div>}
            </div>
        </div>
    else
    return <div role="radio" 
                aria-checked={checked}
                className="col-6 select-option-button align-self-center" 
                tabIndex={0}
                aria-label={text}
                onKeyDown={handleKeyDown}
                onClick={() => onChangeHandler(action)}>
        <button tabIndex={-1} aria-hidden={true} className={"box d-flex justify-center align-items-center flex-column " + (checked ? "active" : "") } >
            <DsyIcon  color={checked ? "#D3135A" : "#1C1B1F" } library="material" name={iconMapping[action]} ></DsyIcon>
            <div className="small-text mt-2" style={{ color: (checked ? "#D3135A" : "#131416")}}>{text1.split("/")[0]}</div>
            {text2 && <div className="small-text f12" style={{ color: (checked ? "#D3135A" : "#667790")}}>({text2}</div>}
        </button>
    </div>
}

export default RenderOptionComponent;
