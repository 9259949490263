
import { useDispatch, useSelector } from 'react-redux';
import {
    set, get
} from '../../reducers/data';
import React, {useState} from 'react';
import cibi from "../../icons/icon/cibi.svg"
import riscaldamento from "../../icons/icon/riscaldamento_r.svg"
import acquacalda from "../../icons/icon/acquacalda_r.svg"
import solare from "../../icons/icon/fotovoltaico_r.svg"
import utils from "../../utils/index.js"

import cooking from "../../icons/icon/new/cooking_r.svg"
import dry_cleaning from "../../icons/icon/new/dry_cleaning_r.svg"
import kitchen from "../../icons/icon/new/kitchen_r.svg"
import laundry from "../../icons/icon/new/laundry_r.svg"
import tv from "../../icons/icon/new/tv_r.svg"
import day from "../../icons/icon/new/day_r.svg"
import night from "../../icons/icon/new/night_r.svg"
import { GriCheckboxComponent } from '../dsy-inhouse/GriCheckboxComponent.jsx';
// import DsyCheckbox from '../../dsy-wrappers/dsy-checkbox.js';


function MultiSelectAtViewComponent({ name, cell, description, choices }) {
    const [announce, setAnnounce] = useState(false)
    const dispatch = useDispatch();
    const data = useSelector(get);
    // const currentValue = data[cell]
    const icons = [riscaldamento, acquacalda, cibi, solare, kitchen, laundry, dry_cleaning, tv, cooking, day, night]
    
    const visible = (x) => { 
        if (x.risp){
            if (parseFloat(data[x.risp]) > 0)
                return false
        }
        if (x.cell === "C53"){
            if (data["PresenzaFotovoltaico"] === "Si" || data["PresenzaFotovoltaico"] == true)
                return false

            const isCondominio = !(data["C11"] === "Monofamiliare" || data["C11"] === "Bifamiliare/Villette a schiera" || data["C11"] === "Bifamiliare")
            if (isCondominio && (data["PresenzaBalcone"] === "No" || data["PresenzaBalcone"] === false || data["PresenzaBalcone"] === undefined || data["PresenzaBalcone"] === undefined))
                return false
        }
        if (process.env.REACT_APP_FOTO_VIRTUALE === "false" && x.cell === "FotoVirtuale")
            return false

        if (!x.conditionalRendering)
            return true
        else 
            return utils.testRendering(x, data)
    }

    const send = (x) => {
        dispatch(set({
            cell: x.cell,
            value: !data[x.cell]
        }))
        // setAnnounce(x.text + " " + (data[x.cell] ? "deselezionato" : "selezionato"))
    }
    const keyDown = (e,x) => {
        if (e.key === "Enter" || e.code == "Space" || e.keyCode == 32 ) {
            send(x)
            e.preventDefault()
        }
        if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
            e.preventDefault();
            const sibling = e.currentTarget.previousElementSibling;
            const child = sibling?.querySelector("input");
            child?.focus()
        } else if (e.key === "ArrowRight" || e.key === "ArrowDown") {
            e.preventDefault();
            const sibling = e.currentTarget.nextElementSibling;
            const child = sibling?.querySelector("input");
            child?.focus()
        }
    }

    const onClick= (e, x) => {
        send(x)
    }

    const singleElement = choices.filter(x => visible(x)).length === 1 ? " border-fix" : ""
    
    return (
        <div  className={"selectatview-component mb-16 " + (data ? "filled" : "")}>
            <div className="title">{name}
                {description && <div className="description">{description}</div>}
            </div>
            {choices && 
            <div className="choice-box" role="group">
                {choices.filter(x => visible(x)).map((x, i) => {
                    const isChecked = data[x.cell] == true
                    return (<label key={i} 
                    onKeyDown={(e) =>keyDown(e, x)}
                    htmlFor={x.cell}
                    className={"single-box d-flex align-items-center " + (isChecked ? "selected" : "") + singleElement } >
                        <div>
                            <GriCheckboxComponent 
                            id={x.cell}
                            onChange={(e) => onClick(e, x)}
                            ariaLabel={x.text}
                            checked={isChecked}
                            />
                        </div>
                            <div aria-hidden={true}>
                                <div className='d-flex align-items-center'>
                                <img src={icons[x.image]} width="32" height={32} role="presentation" alt="" /> <br />
                                <span style={{lineHeight: "2rem"}} aria-hidden={true} >{x.text}</span>
                            </div>
                        </div>
                    </label>
                )})
                }
            </div>}
            {/* <div id="liveRegion" 
                aria-live="polite" 
                className="visually-hidden">{announce}</div> */}
        </div>
    );
}

export default MultiSelectAtViewComponent;